import React, { useState, useEffect, useContext, useRef } from "react";
import "./NavbarContainer.css";
import {
  Download,
  DriveULogo,
  Vector48,
} from "../../Components/AllImportFiles/ImportSvg";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { GoX } from "react-icons/go";
import ServicesDD from "../DropDown/ServicesDD/ServicesDD";
import AboutUsDD from "../DropDown/AboutUsDD/AboutUsDD";
import BusinessDD from "../DropDown/BusinessDD/BusinessDD";
import { DownloadContext } from "../../context/DownloadContextProvider";
import { HamburgerContext } from "../../context/HamburgerContextProvider";
import ReactGA from "react-ga4";

function NavbarContainer() {
  
  // Context HandleScroll Smooth Scroll and Hamburger Menu
  const { handleScroll } = useContext(DownloadContext);
  const { handleHamburger, showMediaIcons } = useContext(HamburgerContext);
  // State for DropDown
  const [stickyClass, setStickyClass] = useState("");
  const [servicesDrop, setServicesDrop] = useState(false);
  const [aboutUsDrop, setAboutUsDrop] = useState(false);
  const [businessDrop, setBusinessDrop] = useState(false);
  // Ref for Outside Click
  const AboutUsRef = useRef(null);
  const ServicesRef = useRef(null);
  const BusinessRef = useRef(null);
  // Function to Open DropDown
  const handleServices = () => {
    setServicesDrop((s) => !s);
    setAboutUsDrop(false);
    setBusinessDrop(false);
  };
  const handleAboutUs = () => {
    setAboutUsDrop((s) => !s);
    setServicesDrop(false);
    setBusinessDrop(false);
  };
  const handleBusiness = () => {
    setBusinessDrop((s) => !s);
    setServicesDrop(false);
    setAboutUsDrop(false);
  };

  // Outside Click
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        aboutUsDrop &&
        AboutUsRef.current &&
        !AboutUsRef.current.contains(e.target)
      ) {
        setAboutUsDrop(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [aboutUsDrop]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        servicesDrop &&
        ServicesRef.current &&
        !ServicesRef.current.contains(e.target)
      ) {
        setServicesDrop(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    window.addEventListener("scroll", () => {
      setServicesDrop(false);
      setAboutUsDrop(false);
    });

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [servicesDrop]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        businessDrop &&
        BusinessRef.current &&
        !BusinessRef.current.contains(e.target)
      ) {
        setBusinessDrop(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [businessDrop]);

  window.addEventListener("scroll", () => {
    setServicesDrop(false);
    setAboutUsDrop(false);
    setBusinessDrop(false);
  });
  // Sticky Navbar
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 10 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  /*
    @author dheeraj
    @perspective 
    adding google analytics in download button 
  */

    const addingGaInDownload = () => {
      ReactGA.gtag("event", "nav_bar_download_app", {
        destination : "scroll"
      });
      handleScroll();
    }

  return (
    <div className={`nav-container ${stickyClass}`}>
      {/* Desktop Navigation */}
      <nav className={`nav-head nav-head-mobile `}>
        {/* Hamburger menu */}
        <div className="hamburger-menu">
          {/* Toggle the hamburger icon on click */}
          <div className="ham-close-menu" onClick={handleHamburger}>
            {/* Show the close icon (X) if media icons are visible, otherwise show the hamburger icon */}
            {showMediaIcons ? (
              <GoX className="mediaicon" />
            ) : (
              <GiHamburgerMenu className="mediaicon" />
            )}
          </div>
        </div>

        {/* DriveU Logo */}
        <div className="driveu-logo" id="driveu-logo-mobile">
          {/* Navigate to the home page when the logo is clicked */}
          <Link to="/">
            <DriveULogo className="main-logo" />
          </Link>
        </div>

        {/* Mobile Navigation Options */}
        <div
          className={
            showMediaIcons ? "nav-options mobile-nav-options" : "nav-options"
          }
        >
          {/* Home Option */}
          <div className="navbar-options" id="navbar-options-mobile">
            {/* Navigate to the home page when "Home" is clicked */}
            <Link to="/" className="nav-home nav-select">
              Home
            </Link>
          </div>

          {/* Services Option */}
          <div
            className="navbar-options"
            id="navbar-options-mobile"
            onClick={handleServices}
            ref={ServicesRef}
          >
            {/* Show the dropdown arrow and call handleServices on click */}
            <div className="nav-services nav-select">Services&nbsp;</div>
            <Vector48
              className={`nav-down-arrow ${
                servicesDrop ? "nav-rotate-arrow" : ""
              }`}
              id="nav-down-arrow"
            />

            {/* Render the Services Dropdown if servicesDrop is true */}
            {servicesDrop ? <ServicesDD /> : null}
          </div>

          {/* Business Option */}
          <div
            className="navbar-options"
            id="navbar-options-mobile"
            onClick={handleBusiness}
            ref={BusinessRef}
          >
            {/* Show the dropdown arrow and call handleBusiness on click */}
            <div className="nav-about-us nav-select">
              For&nbsp;Business&nbsp;
            </div>
            <Vector48
              className={`nav-down-arrow ${
                businessDrop ? "nav-rotate-arrow" : ""
              }`}
              id="nav-arrow-about"
            />

            {/* Render the Business Dropdown if businessDrop is true */}
            {businessDrop ? <BusinessDD /> : null}
          </div>

          {/* About Us Option */}
          <div
            className="navbar-options"
            id="navbar-options-mobile"
            onClick={handleAboutUs}
            ref={AboutUsRef}
          >
            {/* Show the dropdown arrow and call handleAboutUs on click */}
            <div className="nav-about-us nav-select">About&nbsp;Us&nbsp;</div>
            <Vector48
              className={`nav-down-arrow ${
                aboutUsDrop ? "nav-rotate-arrow" : ""
              }`}
              id="nav-arrow-about"
            />

            {/* Render the About Us Dropdown if aboutUsDrop is true */}
            {aboutUsDrop ? <AboutUsDD /> : null}
          </div>

          {/* Download App Button */}
          <div className="navbar-options" id="nav-download-mobile">
            <div className="nav-download-app">
              {/* Handle scrolling to the Download section */}
              <button
                className="nav-download-now nav-select"
                onClick={addingGaInDownload}
              >
                Download App
              </button>
            </div>
          </div>
        </div>

        {/* Extra options for mobile */}
        <div className="navbar-options-mobile">
          {/* Download App */}
          <div className="nav-download-now-mobile" onClick={addingGaInDownload}>
            {/* Show the download icon */}
            <Download className="download-mobile" />
          </div>

          {/* Phone Number */}
          {/* <div className="nav-phone-mobile">
            <a href="tel:+918880712345">
              <Call className="phone-mobile" />
            </a>
          </div> */}
        </div>
      </nav>
    </div>
  );
}

export default NavbarContainer;
