import axios from "axios";
import React, { createContext } from "react";
import { useState } from "react";
export const ApplicationContext = createContext();
function ApplicationContextProvider({ children }) {
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;

  const [garageDataArray, setGarageDataArray] = useState([]);
  const [garageCategory, setGarageCategory] = useState("");
  const [garageInsurer, setGarageInsurer] = useState("");
  const [garageCity, setGarageCity] = useState("");
  const [cityId, setCityId] = useState("");
  const [driverLeaveData, setDriverLeaveData] = useState();
  const [driverID, setDriverID] = useState("");
  const [imeiNum, setImeiNum] = useState("");
  const [changeVacScreen, setChangeVacScreen] = useState("");
  const [backVacPlanner, setBackVacPlanner] = useState(false);
  const [postDriverData, setPostDriverData] = useState({});
  const [activeVacation, setActiveVacation] = useState();
  const [vacationId, setVacationId] = useState("");
  const [myGateToken, setMyGateToken] = useState("");
  const [myGateTokenData, setMyGateTokenData] = useState([] || {});
  const [myGatePopUp, setMyGatePopUp] = useState(false);
  const [myGateBookingID, setMyGateBookingID] = useState("");
  const [pubName, setPubName] = useState("");
  const [consentFlag, setConsentFlag] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [myGateBookingData, setMyGateBookingData] = useState({});
  const [myGateWashToken, setMyGateWashToken] = useState("");
  const [appCarWashTrackSrc, setAppCarWashTrackSrc] = useState("");
  const [appUserID, setAppUserID] = useState("");
  const [trackServiceSrc, setTrackServiceSrc] = useState("");
  const [serviceOrderID, setServiceOrderID] = useState("");
  const [serviceMyGateToken, setServiceMyGateToken] = useState("");
  const [washErrorMessage, setWashErrorMessage] = useState(false);
  const [feedbackScreen, setFeedbackScreen] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [PackageDayDate] = useState([]);

  const [mappedDates, setMappedDates] = useState(new Set());
  const [mappedDatesOneWay, setMappedDatesOneWay] = useState(new Set());
  const [duDailyParams, setDuDailyParams] = useState({
    transmission_type: "",
    car_type: "",
    email: "",
    mobile: "",
    user_id: "",
    name: "",
    src: "",
    is_one_way: false,
  });
  const [driverData, setDriverData] = useState({
    driver_id: "",
    imei_number: "",
    lat: "",
    lng: "",
    email: "",
    mobile: "",
    name: "",
    city_name: "",
    driver_zone: "",
  });
  const [dailyPickupTime, setDailyPickupTime] = useState("");
  const [showPaymentBox, setShowPaymentBox] = useState(false);
  const [showPaymentBoxOneWay, setShowPaymentBoxOneWay] = useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = React.useState(false);
  const [myGatePaymentCheck] = useState({
    paymentCheck: "",
  });
  const [
    showMyGateDriverDetailsPopUp,
    setShowMyGateDriverDetailsPopUp,
  ] = useState(false);

  //  fuel  price state
  const [isFuelPrice, setIsFuelPrice] = useState(false);
  const closeFuelPrice = () => {
    setIsFuelPrice(!isFuelPrice);
  };
  const [isCityPrice, setIsCityPrice] = useState(false);
  const closeCityPrice = () => {
    setIsCityPrice(!isCityPrice);
  };

  const [fuelLoader, setFuelLoader] = useState(true);
  const [stateFuel, setStateFuel] = useState({
    state: "",
    city: "",
  });
  const [cityName, setCityName] = useState("");
  const [fuelData, setFuelData] = useState({});
  const [cityArr, setCityArr] = useState([]);
  const [stateFuelInfo, setStateFuelInfo] = useState([]);
  const [cityFuelInfo, setCityFuelInfo] = useState({});
  const [fuelOption, setFuelOption] = useState(true);
  const [fuelErrorPage, setFuelErrorPage] = useState(false);
  const [citySearchLoader, setCitySearchLoader] = useState(false);
  const [cityValue, setCityValue] = useState("Bangalore");
  const [stateValue, setStateValue] = useState("Karnataka");
  const [partnerBookData, setPartnerBookData] = useState([]);
  const [fuelPriceCoordinate, setFuelPriceCoordinate] = useState({
    latitude: "",
    longitude: "",
  });
  const [feedbackResponse, setFeedbackResponse] = useState([]);
  const [successFeedbackRes, setSuccessFeedbackRes] = useState(false);
  const [duOrderID, setDuOrderID] = useState("");
  const [openDailyFareModal, setOpenDailyFareModal] = useState(false);
  const [dailyPickUpLocationTracker, setDailyPickUpLocationTracker] = useState(
    true
  );
  const [dailyDropLocationTracker, setDailyDropLocationTracker] = useState(
    true
  );
  const [
    dailyBookingSuccessResponse,
    setDailyBookingSuccessResponse,
  ] = useState([] || {});
  const [picKUpLocationError, setPickUpLocationError] = useState(false);
  const [picKUpLocationErrorOneWay, setPickUpLocationErrorOneWay] = useState(
    false
  );
  const [dropLocationErrorOneWay, setDropLocationErrorOneWay] = useState(false);
  const [webViewTitle, setWebViewTitle] = useState("");
  const [evChargingDetails , setEvChargingDetails] = useState("");
  const [driverOffers,setDriverOffers] = useState({});


  const [msilToken, setMsilToken] = useState("");
  const [msilTokenData, setMsilTokenData] = useState({
    name : "",
    longitude : "",
    latitude : "",
    token : "",
    utm_source : ""
  });

  const FuelPriceAPi = (stateData) => {
    setFuelErrorPage(false);
    setFuelLoader(true);
    setIsFuelPrice(false);
    axios({
      method: "GET",
      baseURL: BASE_URL_HULK,
      url: `${BASE_URL_HULK}/care/fuel-info-v2/?state=${stateData.state}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setFuelData(data);
          setStateFuelInfo(data.fuel_info);
          setFuelOption(true);
          data.state_and_cities.forEach((elem) => {
            if (
              elem.title === stateData.state ||
              elem.value === stateData.state
            ) {
              setCityArr(elem.cities);
            }
          });
          setCityName("Select city");
          setFuelLoader(false);
        } else if (data.status === "error") {
          setFuelErrorPage(true);
        }
        setCityValue("");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const cityFuelPrice = (city, toggle) => {
    setFuelErrorPage(false);
    setCitySearchLoader(true);
    axios({
      method: "GET",
      baseURL: BASE_URL_HULK,
      url: `${BASE_URL_HULK}/care/fuel-info-v2/?state=${stateFuel.state}&city=${city}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          if (toggle) {
            setFuelData(data);
            data.state_and_cities.forEach((elem) => {
              if (
                elem.title === stateFuel.state ||
                elem.value === stateFuel.state
              ) {
                setCityArr(elem.cities);
              }
            });

            setCityFuelInfo(data.fuel_info);
            setFuelOption(false);
            setCitySearchLoader(false);
            setFuelLoader(false);
          } else {
            setCityFuelInfo(data.fuel_info);
            setFuelOption(false);
            setIsCityPrice(false);
            setCitySearchLoader(false);
          }
        } else {
          setFuelErrorPage(true);
          setIsCityPrice(false);
          setCitySearchLoader(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleFuelPrice = () => {
    setFuelErrorPage(false);
    setCitySearchLoader(true);
    axios({
      method: "GET",
      baseURL: BASE_URL_HULK,
      url: `${BASE_URL_HULK}/care/fuel-info-v2/?latitude=${fuelPriceCoordinate.latitude}&longitude=${fuelPriceCoordinate.longitude}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setFuelData(data);
          data.state_and_cities.forEach((elem) => {
            if (
              elem.title === stateFuel.state ||
              elem.value === stateFuel.state
            ) {
              setCityArr(elem.cities);
            }
          });
          setCityName(data.fuel_info.city);
          setStateValue(data.state);
          setCityFuelInfo(data.fuel_info);
          setFuelOption(false);
          setCitySearchLoader(false);
          setFuelLoader(false);
          //   } else {
          //     setCityName(city);
          //     setCityFuelInfo(data.fuel_info);
          //     setFuelOption(false);
          //     setIsCityPrice(false);
          //     setCitySearchLoader(false);
          //   }
        } else {
          setFuelErrorPage(true);
          setIsCityPrice(false);
          setCitySearchLoader(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <ApplicationContext.Provider
      value={{
        garageDataArray,
        setGarageDataArray,
        garageCategory,
        setGarageCategory,
        garageInsurer,
        setGarageInsurer,
        garageCity,
        setGarageCity,
        cityId,
        setCityId,
        driverLeaveData,
        setDriverLeaveData,
        changeVacScreen,
        setChangeVacScreen,
        backVacPlanner,
        setBackVacPlanner,
        driverID,
        setDriverID,
        imeiNum,
        setImeiNum,
        postDriverData,
        setPostDriverData,
        activeVacation,
        setActiveVacation,
        vacationId,
        setVacationId,
        myGateToken,
        setMyGateToken,
        myGateTokenData,
        setMyGateTokenData,
        myGatePopUp,
        setMyGatePopUp,
        myGateBookingID,
        setMyGateBookingID,
        pubName,
        setPubName,
        consentFlag,
        setConsentFlag,
        skeletonLoading,
        setSkeletonLoading,
        myGateBookingData,
        setMyGateBookingData,
        myGateWashToken,
        setMyGateWashToken,
        myGatePaymentCheck,
        showMyGateDriverDetailsPopUp,
        setShowMyGateDriverDetailsPopUp,
        isFuelPrice,
        setIsFuelPrice,
        closeFuelPrice,
        isCityPrice,
        setIsCityPrice,
        closeCityPrice,
        cityValue,
        setCityValue,
        stateValue,
        setStateValue,
        fuelLoader,
        setFuelLoader,
        stateFuel,
        setStateFuel,
        FuelPriceAPi,
        fuelData,
        setFuelData,
        cityArr,
        setCityArr,
        cityName,
        setCityName,
        cityFuelPrice,
        stateFuelInfo,
        setStateFuelInfo,
        cityFuelInfo,
        setCityFuelInfo,
        fuelOption,
        setFuelOption,
        fuelErrorPage,
        setFuelErrorPage,
        citySearchLoader,
        setCitySearchLoader,
        appCarWashTrackSrc,
        setAppCarWashTrackSrc,
        appUserID,
        setAppUserID,
        trackServiceSrc,
        setTrackServiceSrc,
        serviceOrderID,
        setServiceOrderID,
        serviceMyGateToken,
        setServiceMyGateToken,
        fuelPriceCoordinate,
        setFuelPriceCoordinate,
        handleFuelPrice,
        washErrorMessage,
        setWashErrorMessage,
        feedbackScreen,
        setFeedbackScreen,
        feedbackResponse,
        setFeedbackResponse,
        successFeedbackRes,
        setSuccessFeedbackRes,
        // Driveu Daily
        selectedDate,
        setSelectedDate,
        PackageDayDate,
        mappedDates,
        setMappedDates,
        duDailyParams,
        setDuDailyParams,
        showPaymentBox,
        setShowPaymentBox,
        dailyPickupTime,
        setDailyPickupTime,
        dailyBookingSuccessResponse,
        setDailyBookingSuccessResponse,
        isOpenCancelModal,
        setIsOpenCancelModal,
        duOrderID,
        setDuOrderID,
        partnerBookData,
        setPartnerBookData,
        driverData,
        setDriverData,
        openDailyFareModal,
        setOpenDailyFareModal,
        dailyPickUpLocationTracker,
        setDailyPickUpLocationTracker,
        picKUpLocationError,
        setPickUpLocationError,
        dailyDropLocationTracker,
        setDailyDropLocationTracker,
        picKUpLocationErrorOneWay,
        dropLocationErrorOneWay,
        setPickUpLocationErrorOneWay,
        setDropLocationErrorOneWay,
        showPaymentBoxOneWay,
        setShowPaymentBoxOneWay,
        mappedDatesOneWay,
        setMappedDatesOneWay,
        webViewTitle,
        setWebViewTitle,

        // msil context start here
        msilTokenData, 
        setMsilTokenData,
        // msil context end here

        // ev charging Details start here
        evChargingDetails, 
        setEvChargingDetails,
        // ev charging Details end here

        // offers start here 
        driverOffers,
        setDriverOffers
        // offers end here

      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
}

export default ApplicationContextProvider;
