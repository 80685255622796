// import ReactGA from 'react-ga4';

// export const getClientId = () => {
//   console.log("clientId => " )
//   return new Promise((resolve) => {
//     ReactGA.ga((tracker) => {
//       console.log("tracker",tracker)
//       const clientId = tracker.get('clientId');
//       resolve(clientId);
//     });
//   });
// };


// implement google analytics 4


export const getClientId = () => {
  return new Promise((resolve, reject) => {
    if (typeof window.gtag !== "function") {
      reject(new Error("Google Analytics (gtag) is not available."));
      return;
    }

    window.gtag("get", process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, "client_id", (clientId) => {
      if (!clientId) {
        reject(new Error("Client ID not retrieved."));
      } else {
        resolve(clientId);
      }
    });
  });
};

