import React, { Fragment } from "react";
import "./PrivacyPolicy.css";
function DriverPrivacyPolicy() {
  return (
    <Fragment>
      <div className="privacy-policy-wrapper">
        <h1 className="head">PRIVACY POLICY</h1>
        <p>
          This Privacy Policy (“Policy”) describes the policies and procedures
          on the collection, use, disclosure, and protection of your information
          when you use our website located at www.driveu.in, or the DriveU
          mobile application (collectively, “DriveU Platform”) made available by
          DRIVEU MOBILITY SOLUTIONS PRIVATE LIMITED (Erstwhile "Humble Mobile Solutions Private Limited") Limited (“DriveU”, “Company”, “we”,
          “us” and “our”), a private company established under the laws of India
          having its registered office at 112/2 KHB Colony, 17th Main, 5th
          Block, Koramangala, Bengaluru – 560095.
        </p>
        <p>
          DriveU is committed to ensuring that your privacy is protected. Should
          we ask you to provide certain information by which you can be
          identified when using this Website/Application, and then you can be
          assured that it will only be used in accordance with this privacy
          statement.
        </p>
        <p>
          DriveU may change this policy from time to time by updating this page.
          You should check this page from time to time to ensure that you are
          happy with any changes.
        </p>

        <p className="sub-head">
          INFORMATION THAT IS OR MAY BE COLLECTED FROM YOU
        </p>
        <p>We may collect the following information:</p>
        <p>
          We use your first and last name to make your experience with DriveU
          seamless.
        </p>
        <p>
          We use your email ID for sending any transactional messages, recipts
          and occasional promotional communication from DriveU.
        </p>
        <p>
          We use your phone number to verify your identity, connect you to our
          drivers and for communication between DriveU, Our Partners and you.
        </p>
        <p>
          We use your location info as provided by you through the DriveU mobile
          application or website to find nearest drivers, calculate your fare
          and other related activities.
        </p>
        <p>
          We use your phone's IMEI number, as provided by you, to identify your
          device uniquely in our system, to help us diagnose any issues, and to
          prevent account fraud.
        </p>
        <p>
          We use analytics tools to understand your usage of the DriveU app, the
          DriveU website and our services to better your experience with us.
        </p>
        <p>
          We use financial info such as authorization of payment wallets and
          other forms of payment providers to help you pay online for our
          services. Any and all online transactions on DriveU are handled by our
          payment partner and secured by them with the best security measures
          available.
        </p>
        <p>
          We store and use user generated content to make your experience
          seamless, to help them provide feedback, review our services and get
          help in relation to the usage of our services.
        </p>

        <p className="sub-head">WHAT WE DO WITH THE INFORMATION WE GATHER</p>
        <p>
          We require this information to understand your needs and provide you
          with a better service, and in particular for the following reasons:
        </p>

        <p>Internal record keeping;</p>
        <p>We may use the information to improve our Services;</p>
        <p>
          We may periodically send promotional emails, SMSs or push
          notifications about new products, special offers or other information
          which we think you may find interesting using the email address which
          you have provided; and From time to time, we may also use your
          information to contact you for market research purposes. We may
          contact you by email, phone, fax or mail. We may use the information
          to customize the Website/Application according to your interests.
        </p>

        <p className="sub-head">SECURITY</p>
        <p>
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorized access or disclosure we have put in place
          suitable physical, electronic and managerial procedures to safeguard
          and secure the information we collect online.
        </p>

        <p className="sub-head">ACCOUNT SECURITY</p>
        <p>
          You are solely responsible for the confidentiality of any password and
          other account information that you create in order to access or use
          the Website/ Application. You agree to notify us immediately on any
          unauthorized use of your account, user name, or password. You also
          agree that DriveU is not liable in any manner for any loss that you
          may incur as a result of any third party using your password, either
          with or without your knowledge. You are solely liable for any losses
          incurred by us, our affiliates, officers, directors, employees,
          consultants, agents, and representatives due to misuse of your account
          or password. You will not under any circumstances use the account,
          username, or password of someone else at any time.
        </p>

        <p className="sub-head">PROHIBITED USES:</p>
        <p>
          You are prohibited from violating or attempting to violate any
          security features of any of our Website/ Application, including,
          without limitation: (a) accessing content or data not intended for
          you, or logging onto a server or account that you are not authorized
          to access; (b) attempting to probe, scan, or test the vulnerability of
          any of our Website/ Application or any associated services, system or
          network, or to breach security or authentication measures without
          proper authorization; (c) using any automated process or service (such
          as, by way of example only, any spider, robot, or automated searching
          or "scraping" tool) to monitor, access or copy any content from any
          Website/Application; (d) interfering or attempting to interfere with
          service to any user, host, or network, including, without limitation,
          by means of submitting a virus to the Website/Application,
          overloading, initiating or facilitating any "denial of service"
          attack, "flooding," "spamming," "mail bombing," or "crashing;" (e)
          using any our Website/ Application or servers to send unsolicited
          e-mail, including, without limitation, promotions, or advertisements
          for products or services; (f) forging any TCP/IP packet header or any
          part of the header information in any e-mail or in any posting using
          any our Website/ Application; or (g) attempting to modify,
          reverse-engineer, decompile, disassemble, or otherwise reduce or
          attempt to reduce to a human-perceivable form any of the source code
          used by DriveU in providing the Website/ Application or our Services.
          Any violation of system or network security may subject you to civil
          and/or criminal liability, and will result in a loss of your ability
          to access and use the Website/Application.
        </p>

        <p className="sub-head">DEBIT / CREDIT CARD DETAILS</p>
        <p>
          You agree, understand and confirm that the credit card details
          provided by you for registering as this Website/ Application will be
          correct and accurate and you shall not use the credit card which is
          not lawfully owned by you. You further agree and undertake to provide
          the correct and valid credit card details to us. Further, the said
          information will not be utilized and shared by us with any of the
          third parties unless required by law, regulation or court order.
        </p>

        <p className="sub-head">HOW WE USE COOKIES</p>
        <p>
          A cookie is a small file which asks permission to be placed on your
          computer's hard drive. Once you agree, the file is added and the
          cookie helps analyze web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences.
        </p>
        <p>
          We use traffic log cookies to identify which pages are being used.
          This helps us analyze data about webpage traffic and improve our
          website in order to tailor it to customer needs. We only use this
          information for statistical analysis purposes and then the data is
          removed from the system.
        </p>
        <p>
          Overall, cookies help us provide you with a better
          Website/Application, by enabling us to monitor which pages you find
          useful and which you do not. A cookie in no way gives us access to
          your computer or any information about you, other than the data you
          choose to share with us.
        </p>
        <p>
          You can choose to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. This may prevent you from
          taking full advantage of the website.
        </p>

        <p className="sub-head">LINKS TO OTHER WEBSITES</p>

        <p>
          Our Application /Website may contain links to other websites of
          interest. However, once you have used these links to leave our site,
          you should note that we do not have any control over that other
          website. Therefore, we cannot be responsible for the protection and
          privacy of any information which you provide whilst visiting such
          sites and such sites are not governed by this privacy statement. You
          should exercise caution and look at the privacy statement applicable
          to the website in question.
        </p>
        <p>
          We will not sell, distribute or lease your personal information to
          third parties unless we have your permission or are required by law to
          do so. We may use your personal information to send you promotional
          information about third parties which we think you may find
          interesting if you tell us that you wish this to happen.
        </p>
        <p>
          Notwithstanding the above, we confirm that we shall at all times be in
          full compliance with the Information Technology (Reasonable security
          practices and procedures and sensitive personal data or information)
          Rules, 2011.
        </p>
      </div>
    </Fragment>
  );
}

export default DriverPrivacyPolicy;
