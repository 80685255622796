import React, { Fragment, useContext } from "react";
import NavbarContainer from "../../parts/Navbar/NavbarContainer";
import "./ContactUs.css";
import BuyAndSell from "../../Components/Home/BuyAndSell/BuyAndSell";
import Footer from "../../parts/Footer/Footer";
import MobileAbout from "../../parts/DropDown/MobileAbout/MobileAbout";
import { HamburgerContext } from "../../context/HamburgerContextProvider";
import { Helmet } from "react-helmet";
import RegistrationForm from "../../Components/Home/PopUpWindow/RegistrationForm/RegistrationForm";
import { DownloadContext } from "../../context/DownloadContextProvider";
function ContactUs() {
  const { showMediaIcons, handleFalse } = useContext(HamburgerContext);

  const { showPopUp } = useContext(DownloadContext);

  const hideHamburger = () => {
    handleFalse();
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>No. 1 Driver Service: Hire Professional Drivers Online</title>
        <meta
          name="description"
          content="Contact us for hiring professional car drivers for local and outstation drives, doorstep car wash and more—services available on-demand."
        />
        <meta
          name="keywords"
          content="car drivers in Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune, Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune car driver services, car drivers in Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune for hire, car driver services in Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune, car drivers for hire in Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune, car drivers for rent in Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune, car driver service in Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune, car driver for hire in Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune, car driver agency Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune, car drivers in Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune for rent, driver agency in Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune, car driver service Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune, Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune car drivers services, hire a car driver in Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune, hire car drivers in Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune, book car drivers in Ahmedabad, Bangalore, Chennai, Delhi NCR, Hyderabad, Kolkata, Mumbai & Pune"
        />
      </Helmet>
      <NavbarContainer />
      <div
        style={{ display: showMediaIcons ? "" : "none" }}
        className="MobileView_Option"
      >
        <MobileAbout />
      </div>
      <div onClick={hideHamburger}>
        <Fragment>
          <div className="AboutUs_Container">
            <div className="AboutUs_LeftContainer">
              <div className="AboutUs_Heading">Contact Us</div>
              <div className="AboutUs_SubHeading">Corporate Office</div>
              <div className="AboutUs_Content">
                112/2, 17th Main Rd, 5th Block, Koramangala, Bengaluru,
                Karnataka 560095
                <div>Email:- care@driveu.in</div>
                <a
                  href="tel: +918880712345"
                  style={{ textDecoration: "none" }}
                  className="AboutUs_Content"
                >
                  Contact:- +918880712345 (All days, 7am to 7pm IST)
                </a>
              </div>
              <div className="AboutUs_Button">
                <button style={{ width: "45%" }} className="AboutUs_Email">
                  <a
                    href="mailto: care@driveu.in"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Email Us{" "}
                  </a>
                </button>
              </div>
            </div>

            <div className="AboutUs_RightContainer">
              <div>
                <div className="AboutUs_Heading">Driver jobs</div>
                <div className="AboutUs_SubHeading">Join as a Driver</div>
                <div className="RightAboutUs_Content">
                  Earn with DriveU without any investments. Download the app and
                  register.
                </div>
              </div>
              <div className="AboutUs_Button">
                <button style={{ width: "45%" }} className="RightAboutUs_Email">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.driveu.partner"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    {" "}
                    Download App{" "}
                  </a>
                </button>
              </div>
            </div>
          </div>
        </Fragment>
        <BuyAndSell />
        <Footer />
      </div>
      {showPopUp ? <div className="overlay" /> : null}
      {showPopUp ? <RegistrationForm className="form_number" /> : null}
    </div>
  );
}

export default ContactUs;
