import React, { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import clevertap from "clevertap-web-sdk";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

// React Icons
import { MdInfoOutline, MdKeyboardArrowRight } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { AiOutlinePercentage } from "react-icons/ai";
import { FaCaretDown } from "react-icons/fa";


// Context
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import { ApplicationContext } from "../../context/ApplicationContextProvider";
import { DownloadContext } from "../../context/DownloadContextProvider";

// Custom Hooks
import useBookDriverApi from "../../CustomHooks/useBookDriverApi";
import useWindowSize from "../../CustomHooks/useWindowSize";
import useGetOutstationCouponData from "../../CustomHooks/useGetOutstationCouponData";

// Components
import OutstationPickup from "./OutstationPickup/OutstationPickup";
import OutstationDrop from "./OutstationDrop/OutstationDrop";
import OutstationFareDetails from "../OneWayBooking/FairEstimate/OutstationFareDetails";
import InsuranceConfirm from "../InsuranceConfirm/InsuranceConfirm";
import ConsentPopUp from "../../MyGate/ConsentBox/ConsentPopUp";
import RegistrationForm from "../../Components/Home/PopUpWindow/RegistrationForm/RegistrationForm";
import PromoPopUpSheet from "../PromoPopUpSheet/PromoPopUpSheet";
import PromoBottomSheet from "../PromoBottomSheet/PromoBottomSheet";
import LoadingSpinner from "../../Uis/Spinner/Spinner";
import TermConditionNew from "../../parts/Term&Condition/TermConditionNew/TermConditionNew";

// BaseWeb
import { Select } from "baseui/select";
import { TimePicker } from "baseui/timepicker";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { Button } from "baseui/button";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "baseui/modal";

// Utils
import { couponVerifyClickedObj } from "../../utils/EventObject";


class ModalStateContainer extends React.Component {
  state = {
    isConfirmationOpen: false,
  };
  toggleConfirm = (open = !this.state.isConfirmationOpen, cb = () => {}) => {
    this.setState({ isConfirmationOpen: open }, cb);
  };
  render() {
    return this.props.children({
      isConfirmationOpen: this.state.isConfirmationOpen,
      toggleConfirm: this.toggleConfirm,
    });
  }
}

const OutstationBooking = () => {
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const CarTypeRef = React.useRef(null);
  const checkRef = useRef(null);
  const tripTypeRef = useRef(null);
  const {
    handleGetAllPromoCode,
    outstationCouponData,
  } = useGetOutstationCouponData();
  const { windowWidth } = useWindowSize();
  const OutstationRoundTripObj = [
    { label: "12 Hrs", id: "#F0F8FF" },
    { label: "16 Hrs", id: "#FAEBD7" },
    { label: "20 Hrs", id: "#00FFFF" },
    { label: "1 Day", id: "#7FFFD4" },
    { label: "2 Days", id: "#F0FFFF" },
    { label: "3 Days", id: "#F5F5DC" },
    { label: "4 Days", id: "#D3D3D3" },
    { label: "5 Days", id: "#00FFF0" },
  ];
  const OutstationOneWayTripObj = [
    { label: "4 Hrs", id: "#F0F8FF" },
    { label: "6 Hrs", id: "#FAEBD7" },
    { label: "8 Hrs", id: "#00FFFF" },
    { label: "10 Hrs", id: "#7FFFD4" },
    { label: "12 Hrs", id: "#F0FFFF" },
    { label: "14 Hrs", id: "#F5F5DC" },
    { label: "16 Hrs", id: "#D3D3D3" },
    { label: "18 Hrs", id: "#00FFF0" },
  ];
  const {
    showCarType,
    setShowCarType,
    showCarModel,
    setShowCarModel,
    handleGetValueToolTip,
    handleGetValueToolTipOutstation,
    setOneWayCarTypeString,
    oneWayCarTypeString,
    premiumOutstation,
    gstOutstation,
    tripType,
    setTripType,
    outstationBookingType,
    setOutstationBookingType,
    estimatedUsageRoundTrip,
    setEstimatedUsageRoundTrip,
    estimatedUsageOneWayTrip,
    setEstimatedUsageOneWayTrip,
    estimatedUsageRoundTripString,
    setEstimatedUsageRoundTripString,
    estimatedUsageOneWayString,
    setEstimatedUsageOneWayString,
    isAuthenticated,
    showBookLoader,
    promoBottomSheet,
    setPromoBottomSheet,
    promoPopUpSheet,
    setPromoPopUpSheet,
    couponLatLngObj,
  } = React.useContext(BookingFlowParaContext);
  const {
    firstCityId,
    secondCityId,
    sendingOutstationData,
    outstationCheckIns,
    setOutstationCheckIns,
    showOutstationFare,
    setShowOutstationFare,
    toggleOutstationFare,
    activeKey,
    setFareEstimateOutstation,
    isOpenIns,
    setIsOpenIns,
    setEventFareEstimate,
    fareParams,
    setFareExtraInfo,
    setCancelFarePopOver,
    fareChangeOutstation,
  } = useContext(BookingFlowDataContext);
  const { handleBookDriverOutstation } = useBookDriverApi();
  const {
    myGateToken,
    setMyGatePopUp,
    consentFlag,
    myGateTokenData,
    msilTokenData
  } = useContext(ApplicationContext);
  const { showPopUp, setShowPopUp, setPreviousChoice } = useContext(
    DownloadContext
  );
  const [defaultDate, setDefaultDate] = useState("");
  // const [fareEstimateMessage, setFareEstimateMessage] = useState("");
  const [couponSuccessMessage, setCouponSuccessMessage] = useState("");
  const [showCouponMessage, setShowCouponMessage] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [showErrorColor, setShowErrorColor] = useState(false);
  const [couponCodeLength, setCouponCodeLength] = useState(false);
  const [outstationFareAmount, setOutstationFareAmount] = useState("");
  const [outstationSpinner, setOutstationSpinner] = useState(false);
  const [dateTimeValue] = useState({
    CurrentYear: "",
    CurrentDate: "",
    currentMonth: "",
  });
  const [compareBaseTime, setCompareBaseTime] = useState("");
  const [showMinTime, setShowMinTime] = useState(false);
  const [isFairEstimateOutstation, setIsFairEstimateOutstation] = useState(
    false
  );
  const [todayDate, setTodayDate] = useState("");
  const [dateArr, setDateArr] = useState([]);
  const [couponText, setCouponText] = useState("");
  const [cookiesHireDrivers] = useCookies();
  const [couponCodeData, setCouponCodeData] = useState([]);
  const [couponCodeToggle, setCouponCodeToggle] = useState(false);
  useEffect(() => {
    if (new Date().getDate() === compareBaseTime || compareBaseTime === "") {
      setShowMinTime(false);
    } else {
      setShowMinTime(true);
    }
  }, [compareBaseTime]);
  const monthDay = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const weekDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const handleDate = () => {
    setShowDate(!showDate);
  };
  const handleDateString = (currentYear, currentMonth, currentDate) => (e) => {
    clevertap.event.push("pickup_time_updated");
    setTodayDate(currentDate);
    setCompareBaseTime(currentDate);
    setShowDate(false);
    dateTimeValue.CurrentYear = currentYear;
    dateTimeValue.CurrentDate =
      currentDate.toString().length === 1 ? `0${currentDate}` : currentDate;
    dateTimeValue.currentMonth =
      (monthDay.indexOf(currentMonth) + 1).toString().length === 1
        ? `0${monthDay.indexOf(currentMonth) + 1}`
        : monthDay.indexOf(currentMonth) + 1;
    setDefaultDate(e.target.innerText);
  };
  const minutes = 15;
  const ms = 1000 * 60 * minutes;
  const currentTime = new Date().getTime();
  const date = new Date(currentTime + 3 * 60 * 60 * 1000);
  let current = new Date(Math.round(date.getTime() / ms) * ms);
  const [val, setVal] = useState(current);
  let new_Date = new Date();
  let DATE = new_Date.getDate();
  let YEAR = new_Date.getFullYear();
  let MONTH = new_Date.getMonth() + 1;
  let StartingDate = DATE.toString().length === 1 ? `0${DATE}` : DATE;
  let StartingMONTH = MONTH.toString().length === 1 ? `0${MONTH}` : MONTH;
  let StartingMinute =
    val.getMinutes() === 0 ? `0${val.getMinutes()}` : val.getMinutes();
  const [dateTimeStringOutstation, setDateTimeStringOutstation] = useState(
    `${StartingDate}/${StartingMONTH}/${YEAR} ${val.getHours()}:${StartingMinute}`
  );
  useEffect(() => {
    if (showCarModel[0].label === "SUV") {
      showCarModel[0].label = "SUV";
    } else if (showCarModel[0].label === "Luxury") {
      showCarModel[0].label = "Luxury";
    }
    setOneWayCarTypeString(
      `${showCarType[0].label} - ${showCarModel[0].label}`
    );
    handleGetValueToolTip();
  }, [showCarType, showCarModel]);

  useEffect(() => {
    var arr = [];
    for (var i = 0; i < 5; i++) {
      var date = new Date();
      var newDate = date.setDate(date.getDate() + i);
      let show = new Date(newDate);
      let onlyDate = show.getDate();
      let year = show.getFullYear();
      var month = monthDay[show.getMonth()];
      var week = weekDay[show.getDay()];
      if (onlyDate.toString().length === 2) {
        arr.push({ week, onlyDate, month, year });
      } else {
        onlyDate = "0" + onlyDate;
        arr.push({ week, onlyDate, month, year });
      }
    }
    let string = arr[0].week + " " + arr[0].onlyDate + " " + arr[0].month;
    setDateArr(arr);
    setDefaultDate(string);
    dateTimeValue.CurrentYear = arr[0].year;
    dateTimeValue.CurrentDate =
      arr[0].onlyDate.toString().length === 1
        ? `0${arr[0].onlyDate}`
        : arr[0].onlyDate;
    dateTimeValue.currentMonth =
      (monthDay.indexOf(arr[0].month) + 1).toString().length === 1
        ? `0${monthDay.indexOf(arr[0].month) + 1}`
        : monthDay.indexOf(arr[0].month) + 1;
  }, []);

  useEffect(() => {
    if (val.getMinutes() === 0) {
      setDateTimeStringOutstation(
        `${dateTimeValue.CurrentDate}/${dateTimeValue.currentMonth}/${
          dateTimeValue.CurrentYear
        } ${val.getHours()}:0${val.getMinutes()}`
      );
    } else if (val.getMinutes() !== 0) {
      setDateTimeStringOutstation(
        `${dateTimeValue.CurrentDate}/${dateTimeValue.currentMonth}/${
          dateTimeValue.CurrentYear
        } ${val.getHours()}:${val.getMinutes()}`
      );
    }
  }, [defaultDate, val]);
  useEffect(() => {
    const carTypeControl = CarTypeRef.current.children[0].children[0];
    const carTypeControlKeyBoard =
      CarTypeRef.current.children[0].children[0].children[0].children[1]
        .children[0];
    carTypeControlKeyBoard.setAttribute("inputmode", "none");
    carTypeControl.style.borderStyle = "none";
    carTypeControl.style.color = "#666";
    carTypeControl.style.backgroundColor = "white";
    carTypeControl.style.borderBottom = "0.2px solid #888";
    const carModelControl = CarTypeRef.current.children[1].children[0];
    const carModelControlKeyBoard =
      CarTypeRef.current.children[1].children[0].children[0].children[1]
        .children[0];
    carModelControlKeyBoard.setAttribute("inputmode", "none");
    // carModelControl.setAttribute("inputmode", "none");
    carModelControl.style.borderStyle = "none";
    carModelControl.style.color = "#666";
    carModelControl.style.backgroundColor = "white";
    carModelControl.style.borderBottom = "0.2px solid #888";

    var control =
      checkRef.current.children[0].children[0].children[0].children[1]
        .children[0];
    control.setAttribute("inputmode", "none");
    var controlBorder = checkRef.current.children[0].children[0];
    controlBorder.style.borderStyle = "none";
    controlBorder.style.borderBottom = "0.2px solid #888";
    const tripTypeControl = tripTypeRef.current.children[0].children[0];
    const tripTypeControlKeyBoard =
      tripTypeRef.current.children[0].children[0].children[0].children[1]
        .children[0];
    const usageTypeControlKeyBoard =
      tripTypeRef.current.children[1].children[0].children[0].children[1]
        .children[0];
    tripTypeControlKeyBoard.setAttribute("inputmode", "none");
    usageTypeControlKeyBoard.setAttribute("inputmode", "none");
    tripTypeControl.style.borderStyle = "none";
    tripTypeControl.style.color = "#666";
    tripTypeControl.style.backgroundColor = "white";
    tripTypeControl.style.borderBottom = "0.2px solid #888";
    const tripUsageControl = tripTypeRef.current.children[1].children[0];
    const tripUsageControlKeyBoard =
      CarTypeRef.current.children[1].children[0].children[0].children[1]
        .children[0];
    tripUsageControlKeyBoard.setAttribute("inputmode", "none");
    // tripUsageControl.setAttribute("inputmode", "none");
    tripUsageControl.style.borderStyle = "none";
    tripUsageControl.style.color = "#666";
    tripUsageControl.style.backgroundColor = "white";
    tripUsageControl.style.borderBottom = "0.2px solid #888";
  }, []);

  const handleCheckCouponCode = () => {
    if (couponText === "") {
      setCouponSuccessMessage("Coupon Code Required");
      setShowCouponMessage(true);
      setTimeout(() => {
        setCouponSuccessMessage("");
      }, 3000);
      setShowErrorColor(false);
    } else if (couponText.length > 16) {
      setCouponSuccessMessage("Character limit exceeded");
      setShowCouponMessage(true);
      setTimeout(() => {
        setCouponSuccessMessage("");
      }, 3000);
      setShowErrorColor(false);
    } else if (!cookiesHireDrivers.userid === false) {
      axios({
        method: "GET",
        baseURL: BASE_URL_HULK,
        url:
          !myGateToken === true
            ? `${BASE_URL_HULK}/discount/validate-promo-code/?city=${firstCityId ||
                couponLatLngObj.city_id}&service_type=classic&booking_type=${outstationBookingType}&format=json&promo_code=${couponText}&src=website&user_id=${
                cookiesHireDrivers.userid
              }&pickup_datetime=${dateTimeStringOutstation}`
            : `${BASE_URL_HULK}/discount/validate-promo-code/?city=${firstCityId ||
                couponLatLngObj.city_id}&service_type=classic&booking_type=${outstationBookingType}&format=json&promo_code=${couponText}&src=my_gate&user_id=${
                cookiesHireDrivers.userid
              }&pickup_datetime=${dateTimeStringOutstation}`,
        withCredentials: false,
        headers: {
          "Content-Type": `application/json`,
        },
      })
        .then(({ data }) => {
          if (data.status === "success") {
            couponVerifyClickedObj.coupon_code = couponText;
            couponVerifyClickedObj.status = "valid";
            couponVerifyClickedObj.client = myGateToken ? "mygate" : "website";
            couponVerifyClickedObj.error_reason = "N/A";
            clevertap.event.push(
              "coupon_verify_clicked",
              couponVerifyClickedObj
            );
            setCouponCodeToggle(true);
            setShowErrorColor(true);
            setCouponSuccessMessage(data.message);
            setCouponCodeData(data);
            setPromoBottomSheet(false);
            setPromoPopUpSheet(false);
            sendingOutstationData.promo_code = couponText;
            setShowCouponMessage(true);
          } else if (data.status === "error") {
            couponVerifyClickedObj.coupon_code = couponText;
            couponVerifyClickedObj.status = "invalid";
            couponVerifyClickedObj.client = myGateToken ? "mygate" : "website";
            couponVerifyClickedObj.error_reason = data.message;
            clevertap.event.push(
              "coupon_verify_clicked",
              couponVerifyClickedObj
            );
            setCouponCodeToggle(false);
            setShowErrorColor(false);
            setCouponSuccessMessage(data.message);
            setTimeout(() => {
              setCouponSuccessMessage("");
            }, 3000);
            setShowCouponMessage(true);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  useEffect(() => {
    if (val.getDate() === +todayDate) {
      setVal(current);
    }
  }, [defaultDate]);

  const handleChangeSetTimeVal = (value) => {
    setVal(value);
    const PickUpTimeUpdated = {
      client: myGateToken ? "mygate" : "website",
    };
    clevertap.event.push("pickup_time_updated", PickUpTimeUpdated);
  };

  const handleOpenSecureToolTip = () => {
    const duSecureInfoViewed = {
      client: myGateToken ? "mygate" : "website",
    };
    clevertap.event.push("du_secure_info_viewed", duSecureInfoViewed);
    setOpen((s) => !s);
  };

  useEffect(() => {
    if (tripType[0].label === "Round Trip") {
      tripType[0].label = "outstation";
    } else if (tripType[0].label === "One Way Trip") {
      tripType[0].label = "one_way_outstation";
    }
    setOutstationBookingType(`${tripType[0].label}`);
    handleGetValueToolTipOutstation();
  }, [tripType]);

  useEffect(() => {
    if (estimatedUsageRoundTrip[0].label === "12 Hrs") {
      estimatedUsageRoundTrip[0].label = "12";
    } else if (estimatedUsageRoundTrip[0].label === "16 Hrs") {
      estimatedUsageRoundTrip[0].label = "16";
    } else if (estimatedUsageRoundTrip[0].label === "20 Hrs") {
      estimatedUsageRoundTrip[0].label = "20";
    } else if (estimatedUsageRoundTrip[0].label === "1 Day") {
      estimatedUsageRoundTrip[0].label = "24";
    } else if (estimatedUsageRoundTrip[0].label === "2 Days") {
      estimatedUsageRoundTrip[0].label = "48";
    } else if (estimatedUsageRoundTrip[0].label === "3 Days") {
      estimatedUsageRoundTrip[0].label = "72";
    } else if (estimatedUsageRoundTrip[0].label === "4 Days") {
      estimatedUsageRoundTrip[0].label = "96";
    } else if (estimatedUsageRoundTrip[0].label === "5 Days") {
      estimatedUsageRoundTrip[0].label = "120";
    }
    setEstimatedUsageRoundTripString(`${estimatedUsageRoundTrip[0].label}`);
  }, [tripType, estimatedUsageRoundTrip]);

  useEffect(() => {
    if (estimatedUsageOneWayTrip[0].label === "4 Hrs") {
      estimatedUsageOneWayTrip[0].label = "4";
    } else if (estimatedUsageOneWayTrip[0].label === "6 Hrs") {
      estimatedUsageOneWayTrip[0].label = "6";
    } else if (estimatedUsageOneWayTrip[0].label === "8 Hrs") {
      estimatedUsageOneWayTrip[0].label = "8";
    } else if (estimatedUsageOneWayTrip[0].label === "10 Hrs") {
      estimatedUsageOneWayTrip[0].label = "10";
    } else if (estimatedUsageOneWayTrip[0].label === "12 Hrs") {
      estimatedUsageOneWayTrip[0].label = "12";
    } else if (estimatedUsageOneWayTrip[0].label === "14 Hrs") {
      estimatedUsageOneWayTrip[0].label = "14";
    } else if (estimatedUsageOneWayTrip[0].label === "16 Hrs") {
      estimatedUsageOneWayTrip[0].label = "16";
    } else if (estimatedUsageOneWayTrip[0].label === "18 Hrs") {
      estimatedUsageOneWayTrip[0].label = "18";
    }
    setEstimatedUsageOneWayString(`${estimatedUsageOneWayTrip[0].label}`);
  }, [tripType, estimatedUsageOneWayTrip]);

  const handleChangeOutstationChecked = (e) => {
    setOutstationCheckIns(e.target.checked);
    const duSecureAdjustObj = {
      action: "",
      client: myGateToken ? "mygate" : "website",
    };
    if (e.target.checked === true) {
      duSecureAdjustObj.action = "applied";
    } else if (e.target.checked === false) {
      duSecureAdjustObj.action = "removed";
    }
    clevertap.event.push("du_secure_adjusted", duSecureAdjustObj);
  };

  const fetchFareOutstation = (fareData) => {
    const driveData = fareData.find(
      (service) => service.service_type === "classic"
    );
    setFareExtraInfo(driveData.extra_info);
    setEventFareEstimate(driveData.total_fare);
    setOutstationFareAmount(driveData.total_fare);
    setFareEstimateOutstation(driveData.fare_details);
    setCancelFarePopOver(driveData.cancel_info);
  };

  const fetchFairApi = () => {
    fareParams.booking_type = outstationBookingType;
    fareParams.car_type = oneWayCarTypeString;
    fareParams.is_insured = +outstationCheckIns;
    fareParams.pickup_datetime = dateTimeStringOutstation;
    fareParams.num_hours =
      outstationBookingType === "outstation"
        ? estimatedUsageRoundTripString
        : estimatedUsageOneWayString;

    fareParams.city_id = !firstCityId === true ? "" : firstCityId;
    fareParams.user_id = cookiesHireDrivers.userid;
    setOutstationSpinner(true);

    const res = axios({
      method: "GET",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/fare-estimate-v2/`,
      withCredentials: false,
      params: fareParams,
      headers: {
        "Content-Type": `application/json`,
      },
    });
    res
      .then(({ data }) => {
        if (data.status === "success") {
          setOutstationSpinner(false);
          setShowOutstationFare(true);
          fetchFareOutstation(data.service_types);
          fareParams.distance = data.distance;
          fareParams.duration = data.duration;
          fareParams.pickup_datetime = dateTimeStringOutstation;
          sendingOutstationData.distance = data.distance;
          sendingOutstationData.duration = data.duration;
          sendingOutstationData.pickup_datetime = dateTimeStringOutstation;
        }
      })
      .catch((err) => {
        setOutstationSpinner(false);
        console.log(err.message);
      });

    // axios({
    //   method: "GET",
    //   baseURL: BASE_URL_HULK,
    //   url:
    //     outstationBookingType === "outstation"
    //       ? `${BASE_URL_HULK}/pricing/fare-estimate/?city_id=${cityId}&service_type=${sendingOutstationData.service_type}&pickup_datetime=${dateTimeStringOutstation}&user_id=${cookiesHireDrivers.userid}&booking_type=${outstationBookingType}&pickup_latitude=${sendingOutstationData.pickup_latitude}&pickup_longitude=${sendingOutstationData.pickup_longitude}&drop_latitude=${sendingOutstationData.drop_latitude}&drop_longitude=${sendingOutstationData.drop_longitude}&num_hours=${estimatedUsageRoundTripString}&calculate_using_location=0`
    //       : `${BASE_URL_HULK}/pricing/fare-estimate/?city_id=${cityId}&service_type=${sendingOutstationData.service_type}&pickup_datetime=${dateTimeStringOutstation}&user_id=${cookiesHireDrivers.userid}&booking_type=${outstationBookingType}&pickup_latitude=${sendingOutstationData.pickup_latitude}&pickup_longitude=${sendingOutstationData.pickup_longitude}&drop_latitude=${sendingOutstationData.drop_latitude}&drop_longitude=${sendingOutstationData.drop_longitude}&num_hours=${estimatedUsageOneWayString}&calculate_using_location=0`,
    //   withCredentials: false,
    //   headers: {
    //     "Content-Type": `application/json`,
    //   },
    // })
    //   .then(({ data }) => {
    //     if (data.status === "success") {
    //       setEventFareEstimate(data.fare_range);
    //       setOutstationSpinner(false);
    //       setOutstationFareAmount(data.fare_range);
    //       setShowOutstationFare(true);
    //       setFareEstimateMessage(data.sub_text);
    //       setFareEstimateOutstation(data.fare_details);
    //       // sendingData.estimated_trip_minutes = data.num_minutes;
    //       // sendingData.estimated_trip_hours = data.num_hours;
    //       // sendingData.pickup_datetime = dateTimeString;
    //     }
    //   })
    //   .catch((err) => {
    //     setOutstationSpinner(false);
    //     console.log(err.message);
    //   });
  };
  let firstToggle = toggleOutstationFare.outstationFirstToggle;
  let secondToggle = toggleOutstationFare.outstationSecondToggle;

  useEffect(() => {
    if (
      firstToggle &&
      secondToggle &&
      firstCityId !== secondCityId &&
      fareChangeOutstation
    ) {
      fetchFairApi();
    }
  }, [
    fareChangeOutstation,
    firstToggle,
    secondToggle,
    dateTimeStringOutstation,
    outstationBookingType,
    estimatedUsageOneWayString,
    estimatedUsageRoundTripString,
    outstationCheckIns,
  ]);
  useEffect(() => {
    setOutstationSpinner(false);
    setShowOutstationFare(false);
  }, [activeKey]);

  function isDisabledTime() {
    const [, date, month] = defaultDate.split(" ");
    const monthsMap = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    const curr = "2024-12-19T17:05:00"
    const currentYear = new Date(curr).getFullYear();

    const currentDate = new Date(currentYear, monthsMap[month], date);

    const currentMonth = currentDate.getMonth(); // Months are zero-based (0-11)
    const currentDay = currentDate.getDate();
    const currentHours = val.getHours();



    // Check if it's December 31st after 12 AM (00:00) and before January 1st 6 AM (06:00)
    if (
      (currentMonth === 11 && currentDay === 31 && currentHours >= 0) || // December 31st
      (currentMonth === 0 && currentDay === 1 && currentHours < 6) // January 1st
    ) {
      return true; // Time is within the disabled range
    }

    return false; // Time is outside the disabled range
  }

  const handleCreateOutstationBooking = () => {
    if (sendingOutstationData.pickup_address === "") {
      toast.error("Enter pickup Address");
    } else if (sendingOutstationData.drop_address === "") {
      toast.error("Enter drop Address");
    } else {
      sendingOutstationData.booking_type = outstationBookingType;
      sendingOutstationData.car_type = oneWayCarTypeString;
      sendingOutstationData.city = firstCityId;
      sendingOutstationData.my_gate_session_id = myGateToken;
      sendingOutstationData.pickup_datetime = dateTimeStringOutstation;
      if (outstationBookingType === "outstation") {
        sendingOutstationData.estimated_trip_hours = estimatedUsageRoundTripString;
      } else {
        sendingOutstationData.estimated_trip_hours = estimatedUsageOneWayString;
      }

      if (outstationCheckIns === false) {
        setIsOpenIns(true);
      } else {
        sendingOutstationData.is_insured = outstationCheckIns;

        if (myGateToken && consentFlag === false) {
          setMyGatePopUp(true);
          setPreviousChoice("MyGateRequestBookingOutstation");
        } else {
          if (isAuthenticated === false && !myGateToken === true) {
            setPreviousChoice("RequestBookingOutstation");
            setShowPopUp(true);
          } else {
            // Usage
            if (isDisabledTime()) {

              toast.error(
                "Error Outstation unavailable due to high New Year demand - from 31/12/24 - 12:00am to 1/01/25 - 05:59am. We regret any inconvenience.",
                {
                  icon: "⛔️",
                  style: {
                    borderRadius: "10px",
                    background: "#ffd1d1",
                    color: "#9e1313",
                  },
                }
              );
              // Perform actions to disable functionalities or display a message here
            } else {
              handleBookDriverOutstation();
              // Enable functionalities or proceed with regular operations here
            }
          }
        }
      }
    }
  };

  const handleFillPromoCode = (promoCode) => {
    setCouponText(promoCode);
    setCouponCodeLength(true);
    axios({
      method: "GET",
      baseURL: BASE_URL_HULK,
      url:
        !myGateToken === true
          ? `${BASE_URL_HULK}/discount/validate-promo-code/?city=${firstCityId ||
              couponLatLngObj.city_id}&service_type=classic&booking_type=${outstationBookingType}&format=json&promo_code=${promoCode}&src=website&user_id=${
              cookiesHireDrivers.userid
            }&pickup_datetime=${dateTimeStringOutstation}`
          : `${BASE_URL_HULK}/discount/validate-promo-code/?city=${firstCityId ||
              couponLatLngObj.city_id}&service_type=classic&booking_type=${outstationBookingType}&format=json&promo_code=${promoCode}&src=my_gate&user_id=${
              cookiesHireDrivers.userid
            }&pickup_datetime=${dateTimeStringOutstation}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setCouponCodeData(data);
          setShowErrorColor(true);
          setCouponSuccessMessage(data.message);
          sendingOutstationData.promo_code = promoCode;
          setCouponCodeToggle(true);
          setShowCouponMessage(true);
          setPromoBottomSheet(false);
          setPromoPopUpSheet(false);
        } else if (data.status === "error") {
          setCouponCodeToggle(false);
          setShowErrorColor(false);
          setCouponSuccessMessage(data.message);
          setTimeout(() => {
            setCouponSuccessMessage("");
          }, 3000);
          setShowCouponMessage(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleApplyPromocode = () => {
    sendingOutstationData.pickup_datetime = dateTimeStringOutstation;
    if (windowWidth > 768) {
      if (
        !myGateToken === true &&
        (!cookiesHireDrivers.userid === true ||
          (cookiesHireDrivers.userid === "undefined" &&
            typeof cookiesHireDrivers.userid === "string"))
      ) {
        setShowPopUp(true);
        setPreviousChoice("NavbarSignUp");
      } else if (myGateToken && consentFlag === false) {
        setMyGatePopUp(true);
      } else {
        handleGetAllPromoCode();
        setPromoPopUpSheet(true);
      }
    } else {
      if (
        !myGateToken === true &&
        (!cookiesHireDrivers.userid === true ||
          (cookiesHireDrivers.userid === "undefined" &&
            typeof cookiesHireDrivers.userid === "string"))
      ) {
        setShowPopUp(true);
        setPreviousChoice("NavbarSignUp");
      } else if (myGateToken && consentFlag === false) {
        setMyGatePopUp(true);
      } else {
        handleGetAllPromoCode();
        setPromoBottomSheet(true);
      }
    }
  };
  const handleRemoveSuccessCoupon = () => {
    setCouponCodeToggle(false);
    setCouponSuccessMessage("");
    setCouponText("");
    setCouponCodeLength(false);
  };
  const handleGetOtherOffers = () => {
    if (windowWidth > 768) {
      setPromoPopUpSheet(true);
    } else {
      setPromoBottomSheet(true);
    }
  };

  return (
    <>
      <div
        style={{ display: isDisabledTime() ? "block" : "none" }}
        className="newYearMessage"
      >
        Outstation services may not be available from 12:00 a.m. on 31/12/24 to
        5:59 a.m. on 01/01/25 due to high demand during New Year celebrations.
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="roundWay">
          <div
            style={{ marginTop: "-1.5rem" }}
            className="inputBox roundWayInputBox"
          >
            <div style={{ color: "#888" }} className="fontMedium">
              Choose Location
            </div>
            <OutstationPickup
              handleRemoveSuccessCoupon={handleRemoveSuccessCoupon}
            />
            <OutstationDrop />
          </div>
          <div>
            <div className="oneWayBottomDiv">
              <div className="RoundWayPkgBlock" style={{ width: "96%" }}>
                <div className="fontRoundWay" style={{ color: "#888" }}>
                  Select Trip Type and Estimated Usage
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "1.25rem",
                  }}
                  ref={tripTypeRef}
                >
                  <Select
                    clearable={false}
                    options={[
                      { label: "Round Trip", id: "#F0F8FF" },
                      { label: "One Way Trip", id: "#FAEBD7" },
                    ]}
                    value={tripType}
                    searchable={false}
                    onChange={(params) => setTripType(params.value)}
                  />
                  {tripType[0].label === "outstation" ||
                  tripType[0].label === "Round Trip" ? (
                    <Select
                      clearable={false}
                      options={OutstationRoundTripObj}
                      height="200px"
                      value={estimatedUsageRoundTrip}
                      searchable={false}
                      onChange={(params) =>
                        setEstimatedUsageRoundTrip(params.value)
                      }
                      overrides={{
                        Dropdown: {
                          style: {
                            height: "150px",
                          },
                        },
                      }}
                    />
                  ) : (
                    <Select
                      clearable={false}
                      options={OutstationOneWayTripObj}
                      height="200px"
                      value={estimatedUsageOneWayTrip}
                      searchable={false}
                      onChange={(params) =>
                        setEstimatedUsageOneWayTrip(params.value)
                      }
                      overrides={{
                        Dropdown: {
                          style: {
                            height: "150px",
                          },
                        },
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  marginTop: "0.8rem",
                }}
              >
                <div className="fontRoundWay" style={{ color: "#888" }}>
                  Date & Time
                </div>
                <div className="DataAndTimeInput">
                  <div className="Date" onClick={handleDate}>
                    <div style={{ width: "70%" }}>
                      <input
                        placeholder="Select Date"
                        className="dateInput"
                        type="none"
                        value={defaultDate}
                        readOnly
                        required
                      />
                    </div>
                    <div style={{display : "flex" , justifyContent: "start" , alignItems : "center"}}>
                    <FaCaretDown size={15} style={{ width: "0.7em"}} />
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setShowDate(false);
                    }}
                    className="Time"
                    ref={checkRef}
                  >
                    {val.getDate() === +todayDate ? (
                      <TimePicker
                        className="carDetailOption"
                        value={val}
                        onChange={handleChangeSetTimeVal}
                        minTime={new Date(Math.round(date.getTime() / ms) * ms)}
                      />
                    ) : (
                      <TimePicker
                        className="carDetailOption"
                        value={val}
                        onChange={handleChangeSetTimeVal}
                        minTime={
                          showMinTime
                            ? ""
                            : new Date(Math.round(date.getTime() / ms) * ms)
                        }
                      />
                    )}
                  </div>
                </div>
                <div style={{ marginTop: "0.5rem" }} className="dateDiv">
                  {dateArr.map((elem, index) => (
                    <div
                      className="dateOption"
                      onClick={handleDateString(
                        elem.year,
                        elem.month,
                        elem.onlyDate
                      )}
                      style={{
                        display: showDate ? "" : "none",
                      }}
                      key={index}
                    >
                      {elem.week} {elem.onlyDate} {elem.month}
                    </div>
                  ))}
                </div>
              </div>
              <div onClick={() => {}} className="RoundWayCarType">
                <div className="fontRoundWay" style={{ color: "#888" }}>
                  Car Type
                </div>
                <div className="CarType" ref={CarTypeRef}>
                  <Select
                    clearable={false}
                    options={[
                      { label: "Manual", id: "#F0F8FF" },
                      { label: "Automatic", id: "#FAEBD7" },
                    ]}
                    value={showCarType}
                    searchable={false}
                    onChange={(params) => setShowCarType(params.value)}
                  />
                  <Select
                    clearable={false}
                    options={[
                      { label: "Hatchback", id: "#F0F8FF" },
                      { label: "Sedan", id: "#FAEBD7" },
                      { label: "SUV", id: "#00FFFF" },
                      { label: "Luxury", id: "#7FFFD4" },
                    ]}
                    value={showCarModel}
                    searchable={false}
                    onChange={(params) => setShowCarModel(params.value)}
                  />
                </div>
              </div>

              {couponCodeToggle === false ? (
                <div
                  onClick={handleApplyPromocode}
                  style={{ marginTop: "0.75rem" }}
                >
                  <div className="newPromoContainer">
                    <AiOutlinePercentage className="newPromoDiscountImage" />
                    <div className="fontMedium couponText">
                      <span>Apply Coupon</span>
                      <MdKeyboardArrowRight
                        style={{ color: "#191C19", fontSize: "1rem" }}
                      />
                    </div>
                  </div>

                  {myGateToken ? (
                    <div
                      style={{
                        display:
                          myGateTokenData.length === 0 ? "none" : "block",
                      }}
                      className="actionOffer"
                    >
                      Click above to see offers
                    </div>
                  ) : (
                    <div
                      style={{
                        display:
                          !cookiesHireDrivers.userid === true ||
                          cookiesHireDrivers.userid === "undefined"
                            ? "none"
                            : "block",
                      }}
                      className="actionOffer"
                    >
                      Click above to see offers
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="newPromoSuccessContainer">
                    <div className="successPromoContainer">
                      <div className="successPromoF1">
                        <div className="PromoF1">
                          <div className="PromoF11">
                            <FaCheckCircle />
                          </div>
                          <div className="PromoF12">{couponText} Applied</div>
                        </div>
                        <div className="PromoF2">
                          {couponCodeData.title === ""
                            ? "Code Applied"
                            : couponCodeData.title}
                        </div>
                      </div>
                      <div
                        className="successPromoF2"
                        onClick={handleRemoveSuccessCoupon}
                      >
                        Remove
                      </div>
                    </div>
                    <hr className="successLineL1" />
                    <div
                      className="otherOffersO1"
                      onClick={handleGetOtherOffers}
                    >
                      Other Offers
                    </div>
                  </div>
                </>
              )}
              <div
                style={{
                  color: "#888",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginBottom: "0.4rem",
                  marginTop: "0.8rem",
                  fontFamily: "Circular-Std-Book",
                }}
              >
                SECURE YOUR BOOKING &nbsp;
                <ModalStateContainer>
                  {({ isConfirmationOpen, toggleConfirm }) => (
                    <React.Fragment>
                      <MdInfoOutline
                        style={{
                          fontSize: "14px",
                          color : msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40",
                          cursor: "pointer",
                          fontFamily: "Circular-Std-Book",
                        }}
                        onClick={handleOpenSecureToolTip}
                      />

                      <Modal onClose={() => setOpen(false)} isOpen={isOpen}>
                        <ModalHeader
                          style={{
                            fontFamily: "Circular-Std-Bold",
                            color: "#09081a",
                            opacity: 0.7,
                            outline: "none",
                          }}
                        >
                          DriveU Secure
                        </ModalHeader>

                        <ModalBody>
                          <center
                            style={{
                              fontFamily: "Circular-Std-Book",
                            }}
                          >
                            <h4
                              style={{
                                marginBottom: "0.5rem",
                                color: "#09081a",
                                opacity: 0.7,
                              }}
                            >
                              Vehicle Damage Protection Plan
                            </h4>
                            <table>
                              <tbody className="insTableBody">
                                <tr>
                                  <td>DU Secure Fee&nbsp;</td>
                                  <td>&nbsp;₹{`${premiumOutstation}`}/-</td>
                                </tr>
                                <tr>
                                  <td>GST&nbsp;</td>
                                  <td>&nbsp;₹{`${gstOutstation}`}/-</td>
                                </tr>
                                <tr>
                                  <td>Total&nbsp;</td>
                                  <td>
                                    &nbsp;₹
                                    {`${+premiumOutstation + +gstOutstation}`}/-
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </center>
                          <br />
                          <ol
                            style={{
                              fontFamily: "Circular-Std-Book",
                              marginLeft: "24px",
                              color: "#09081a",
                              opacity: 0.7,
                            }}
                          >
                            <li>Vehicle damages up to ₹15,000*</li>
                            <li>
                              Claim to be raised within 3 days of trip end
                            </li>
                          </ol>
                        </ModalBody>
                        <ModalFooter>
                          <center
                            style={{
                              fontFamily: "Circular-Std-Book",
                              color: "#666",
                              marginBottom: "16px",
                            }}
                          >
                            {myGateToken ? (
                              <div
                                style={{
                                  opacity: "0.7",
                                  color : msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40",
                                }}
                                onClick={() => toggleConfirm(true)}
                              >
                                View Terms and Conditions
                              </div>
                            ) : (
                              <Link
                                to="/tnc"
                                style={{
                                  opacity: "0.7",
                                  color: myGateToken ? " #f93356" : "#127e40",
                                }}
                              >
                                View Terms and Conditions 
                              </Link>
                            )}
                          </center>
                        </ModalFooter>
                      </Modal>

                      <Modal
                        onClose={() => toggleConfirm(false)}
                        isOpen={isConfirmationOpen}
                      >
                        <ModalHeader>DRIVEU TERMS AND CONDITIONS</ModalHeader>
                        <ModalBody>
                          <div
                            style={{
                              width: "100%",
                              height: "500px",
                              overflow: "scroll",
                            }}
                          >
                            <TermConditionNew />
                          </div>
                        </ModalBody>
                      </Modal>
                    </React.Fragment>
                  )}
                </ModalStateContainer>
              </div>
              <Checkbox
                checked={outstationCheckIns}
                onChange={handleChangeOutstationChecked}
                labelPlacement={LABEL_PLACEMENT.right}
                overrides={{
                  Label: {
                    style: ({ $theme }) => ({
                      color: "#888",
                      fontSize: "12px",
                      fontWeight: "400px",
                      marginBottom: "0.5rem",
                      fontFamily: "Circular-Std-Book",
                      marginRight: "1rem",
                      lineHeight: "150%",
                    }),
                  },
                }}
              >
                Get vehicle damage protection plan for your car for ₹
                {`${+premiumOutstation}`}+GST T&C apply*
              </Checkbox>
              <div
                style={{
                  display: showOutstationFare ? "" : "none",
                }}
              >
                <div
                  style={{
                    color: "#888",
                    fontSize: "12px",
                    fontWeight: "400",
                    textAlign: "center",
                    marginBottom: "0.5rem",
                    fontFamily: "Circular-Std-Book",
                  }}
                >
                  -----------
                  <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                    FARE ESTIMATE
                  </span>
                  -------------
                </div>
                {outstationSpinner ? (
                  <center>
                    <LoadingSpinner />
                  </center>
                ) : (
                  <div
                    style={{
                      color: "#0085b2",
                      fontSize: "30px",
                      fontWeight: "400",
                      marginBottom: "0.5rem",
                      textAlign: "center",
                      fontFamily: "Circular-Std-Book",
                    }}
                  >
                    ₹{outstationFareAmount}
                    <MdInfoOutline
                      style={{
                        fontSize: "30px",
                        cursor: "pointer",
                        color: "gray",
                        paddingTop: "0.4rem",
                      }}
                      onClick={() => setIsFairEstimateOutstation(true)}
                    />
                    {isFairEstimateOutstation ? (
                      <OutstationFareDetails
                        isFairEstimateOutstation={isFairEstimateOutstation}
                        setIsFairEstimateOutstation={
                          setIsFairEstimateOutstation
                        }
                      />
                    ) : null}
                  </div>
                )}
                <div
                  style={{
                    color: "#888",
                    fontSize: "12px",
                    fontWeight: "400",
                    textAlign: "center",
                    marginBottom: "0.2rem",
                    fontFamily: "Circular-Std-Book",
                    marginRight: "1rem",
                  }}
                >
                  {/* {fareEstimateMessage} */}
                  This is just an estimate, actual fare will be calculated after
                  the drive.
                </div>
              </div>
            </div>
            <br />
            {showBookLoader ? (
              <Button
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  backgroundColor: myGateToken ? " #f93356" : "#127e40",
                  color: "#ffffff",
                  marginTop: "-1rem",
                  paddingLeft: "0rem !important",
                }}
                isLoading
              />
            ) : (
              <Button
                onClick={handleCreateOutstationBooking}
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  backgroundColor : msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40",
                  color: "#ffffff",
                  paddingLeft: "0rem !important",
                  marginTop: "-1rem",
                }}
                id="Outstation-Booking"
              >
                <span
                  style={{
                    color: "#ffffff",
                    fontFamily: "Circular-Std-Medium",
                  }}
                >
                  Request Driver
                </span>
              </Button>
            )}
          </div>
        </div>
      </form>
      <PromoBottomSheet
        promoBottomSheet={promoBottomSheet}
        setPromoBottomSheet={setPromoBottomSheet}
        setCoupanSucessMessage={setCouponSuccessMessage}
        coupanSucessMessage={couponSuccessMessage}
        couponCodeLength={couponCodeLength}
        setCouponCodeLength={setCouponCodeLength}
        checkingCouponApi={handleCheckCouponCode}
        coupanText={couponText}
        setCoupanText={setCouponText}
        showCoupanMessage={showCouponMessage}
        showErrorColor={showErrorColor}
        handleFillPromoCode={handleFillPromoCode}
        couponData={outstationCouponData}
      />
      <PromoPopUpSheet
        promoPopUpSheet={promoPopUpSheet}
        setPromoPopUpSheet={setPromoPopUpSheet}
        setCoupanSucessMessage={setCouponSuccessMessage}
        coupanSucessMessage={couponSuccessMessage}
        couponCodeLength={couponCodeLength}
        setCouponCodeLength={setCouponCodeLength}
        coupanText={couponText}
        setCoupanText={setCouponText}
        checkingCouponApi={handleCheckCouponCode}
        showCoupanMessage={showCouponMessage}
        showErrorColor={showErrorColor}
        handleFillPromoCode={handleFillPromoCode}
        couponData={outstationCouponData}
      />
      {isOpenIns ? <InsuranceConfirm /> : null}
      <ConsentPopUp couponCodeToggle={couponCodeToggle} />
      {showPopUp ? <div className="overlay" /> : null}
      {showPopUp ? <RegistrationForm className="form_number" /> : null}
    </>
  );
};

export default OutstationBooking;
