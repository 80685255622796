import React, { createContext, useRef, useState } from "react";
// import { useLocation } from "react-router-dom";
export const DownloadContext = createContext();
// ref={myRef}
function DownloadContextProvider({ children }) {
  const myRef = useRef();
  // const location = useLocation();
  const handleScroll = () => { 
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const [showPopUp, setShowPopUp] = useState(false);
  const [isFastTagLoading, setIsFastTagLoading] = useState(false);
  const [previousChoice, setPreviousChoice] = useState("home");
  const handleClosePopUpOTP = () => {
    setShowPopUp(false);
  };
  return (
    <DownloadContext.Provider
      value={{
        handleScroll,
        myRef,
        showPopUp,
        setShowPopUp,
        previousChoice,
        setPreviousChoice,
        isFastTagLoading,
        setIsFastTagLoading,
        handleClosePopUpOTP
      }}
    >
      {children}
    </DownloadContext.Provider>
  );
}
export default DownloadContextProvider;
