import React from "react";
import "./Popular_place_ban.css";
import GurugramCityJson from "../../CityDataJson/gurugramCityData.json";
import ReactGA from  "react-ga4";
import { useNavigate } from "react-router-dom";

function PopularPlace() {
  const navigate = useNavigate();
  const handleCityChange = (link,alt_text) =>{
    ReactGA.gtag("event", "city_area_clicked", {    
      area: alt_text, 
    });
    navigate(link)
    
  }
  const Popular_Place_1 =
    "https://ik.imagekit.io/driveu/Website_New/Popular_Place_1_SSI7jrorx.png";
  return (
    <div className="Popular_place_main_div_ban">
      <div className="Popular_place_heading_ban">Popular Places in Gurgaon</div>
      <div className="Popular_place_subheading_ban">
        We built DriveU around the belief that when people are treated better,
        they provide better service.
      </div>
      <div className="Popular_place_third_div_ban">

      {
          GurugramCityJson.map((ele, i )=>{
            const {link ,alt_text} = ele;
            return (
              <div
              key={i}
              onClick={()=> handleCityChange(link, alt_text)}
              className="popular_first_div_ban"
            >
              <div className="popular_first_div_img_ban">
                <img src={Popular_Place_1} alt={alt_text} />
              </div>
              <div className="popular_first_div_text_ban">{alt_text}</div>
            </div>
            )
          })
        }
        </div>
    </div>
  );
}

export default PopularPlace;
