import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import clevertap from "clevertap-web-sdk";
import { useCookies } from "react-cookie";

// Styling
import "./OneWayBooking.css";

// BaseWeb
import { Select } from "baseui/select";
import { TimePicker } from "baseui/timepicker";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { Button } from "baseui/button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  FocusOnce,
  ModalFooter,
} from "baseui/modal";

// React Icons
import { MdInfoOutline, MdKeyboardArrowRight } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { AiOutlinePercentage } from "react-icons/ai";

// Context
import { BookingFlowDataContext } from "../../context/BookingFlowDataContextProvider";
import { BookingFlowParaContext } from "../../context/BookingFlowParaProvider";
import { DownloadContext } from "../../context/DownloadContextProvider";
import { ApplicationContext } from "../../context/ApplicationContextProvider";

// Custom Hooks
import useBookDriverApi from "../../CustomHooks/useBookDriverApi";
import useWindowSize from "../../CustomHooks/useWindowSize";
import useGetCouponData from "../../CustomHooks/useGetCouponData";

// Utils
import { couponVerifyClickedObj } from "../../utils/EventObject";

// Components
import OneWayInput from "./OneWayInput/OneWayInput";
import RegistrationForm from "../../Components/Home/PopUpWindow/RegistrationForm/RegistrationForm";
import FairEstimate from "./FairEstimate/FairEstimate";
import OneWaySecondInput from "./OneWaySecondInput/OneWaySecondInput";
import LoadingSpinner from "../../Uis/Spinner/Spinner";
import InsuranceConfirm from "../InsuranceConfirm/InsuranceConfirm";
import ConsentPopUp from "../../MyGate/ConsentBox/ConsentPopUp";
import TermConditionNew from "../../parts/Term&Condition/TermConditionNew/TermConditionNew";
import PromoPopUpSheet from "../PromoPopUpSheet/PromoPopUpSheet";
import PromoBottomSheet from "../PromoBottomSheet/PromoBottomSheet";
import toast from "react-hot-toast";
import { FaCaretDown } from "react-icons/fa";

class ModalStateContainer extends React.Component {
  state = {
    isConfirmationOpen: false,
  };
  toggleConfirm = (open = !this.state.isConfirmationOpen, cb = () => {}) => {
    this.setState({ isConfirmationOpen: open }, cb);
  };
  render() {
    return this.props.children({
      isConfirmationOpen: this.state.isConfirmationOpen,
      toggleConfirm: this.toggleConfirm,
    });
  }
}

function OneWayBooking() {
  const checkRef = useRef(null);
  const CarTypeRef = useRef(null);
  const whenTypeRef = useRef(null);
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { handleBookDriverBooking } = useBookDriverApi();
  const { handleGetAllPromoCode } = useGetCouponData();
  const { windowWidth } = useWindowSize();

  const {
    firstCityId,
    secondCityId,
    sendingData,
    toggleFareEstimate,
    setFareEstimateDetail,
    setMisMatchCityId,
    oneWayChecked,
    setOneWayChecked,
    isOpenIns,
    setIsOpenIns,
    isErrMsgBooking,
    showFareEstimate,
    setShowFareEstimate,
    activeKey,
    setDateTimeAllWay,
    showETA,
    etaOneWayTime,
    showWhen,
    setShowWhen,
    setOneWayETATimeString,
    setEventFareEstimate,
    couponData,
    fareParams,
    setFareExtraInfo,
    setCancelFarePopOver,
  } = useContext(BookingFlowDataContext);

  const {
    premium,
    handleGetValueToolTip,
    gst,
    oneWayCarTypeString,
    setOneWayCarTypeString,
    showCarType,
    setShowCarType,
    showCarModel,
    setShowCarModel,
    showBookLoader,
    isAuthenticated,
    promoBottomSheet,
    setPromoBottomSheet,
    promoPopUpSheet,
    setPromoPopUpSheet,
    couponLatLngObj,
  } = React.useContext(BookingFlowParaContext);

  // All state define here ---- starting
  const [cookiesHireDrivers] = useCookies();
  const [dateArr, setDateArr] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isFairEstimate, setIsFairEstimate] = useState(false);
  const [defaultDate, setDefaultDate] = useState("");
  const [coupanText, setCoupanText] = useState("");
  const [coupanSucessMessage, setCoupanSucessMessage] = useState("");
  const [showCoupanMessage, setShowCoupanMessage] = useState(false);
  const { showPopUp, setShowPopUp, setPreviousChoice } = useContext(
    DownloadContext
  );
    //=======Msilparams Object start here ========

  const {msilTokenData} = useContext(ApplicationContext);

    //=======Msilparams Object end here ========

  const {
    myGateToken,
    setMyGatePopUp,
    consentFlag,
    myGateTokenData,
  } = useContext(ApplicationContext);
  // const [fareEstimateMessage, setFareEstimateMessage] = useState("");
  const [showErrorColor, setShowErrorColor] = useState(false);
  const [couponCodeLength, setCouponCodeLength] = useState(false);

  const [fareEstimate, setFareEstimate] = useState("");
  const [dateTimeValue] = useState({
    CurrentYear: "",
    CurrentDate: "",
    currentMonth: "",
  });
  const [fareSpinner, setFareSpinner] = useState(false);
  const [compareBaseTime, setCompareBaseTime] = useState("");
  const [showMinTime, setShowMinTime] = useState(false);
  const [todayDate, setTodayDate] = useState("");
  const [couponCodeData, setCouponCodeData] = useState([]);
  const [couponCodeToggle, setCouponCodeToggle] = useState(false);

  // All function and const define Here

  useEffect(() => {
    if (new Date().getDate() === compareBaseTime || compareBaseTime === "") {
      setShowMinTime(false);
    } else {
      setShowMinTime(true);
    }
  }, [compareBaseTime]);

  const minutes = 15;
  const ms = 1000 * 60 * minutes;
  const currentTime = new Date().getTime();
  // 0.5 * 20 * 60 * 1000
  const date = new Date(currentTime + 1.12 * 60 * 60 * 1000);
  let currentPickUpDate = new Date(Math.round(date.getTime() / ms) * ms);
  const [val, setVal] = useState(currentPickUpDate);

  let new_Date = new Date();
  let DATE = new_Date.getDate();
  let YEAR = new_Date.getFullYear();
  let MONTH = new_Date.getMonth() + 1;
  let StartingDate = DATE.toString().length === 1 ? `0${DATE}` : DATE;
  let StartingMONTH = MONTH.toString().length === 1 ? `0${MONTH}` : MONTH;
  let StartingMinute =
    val.getMinutes() === 0 ? `0${val.getMinutes()}` : val.getMinutes();
  const [dateTimeString, setDateTimeString] = useState(
    `${StartingDate}/${StartingMONTH}/${YEAR} ${val.getHours()}:${StartingMinute}`
  );

  useEffect(() => {
    if (showCarModel[0].label === "SUV") {
      showCarModel[0].label = "SUV";
    } else if (showCarModel[0].label === "Luxury") {
      showCarModel[0].label = "Luxury";
    }
    setOneWayCarTypeString(
      `${showCarType[0].label} - ${showCarModel[0].label}`
    );
    handleGetValueToolTip();
  }, [showCarType, showCarModel]);

  const monthDay = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const weekDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const handleDate = () => {
    setShowDate(!showDate);
  };
  const handleDateString = (currentYear, currentMonth, currentDate) => (e) => {
    setTodayDate(currentDate);
    setCompareBaseTime(currentDate);
    setShowDate(false);
    dateTimeValue.CurrentYear = currentYear;
    dateTimeValue.CurrentDate =
      currentDate.toString().length === 1 ? `0${currentDate}` : currentDate;
    dateTimeValue.currentMonth =
      (monthDay.indexOf(currentMonth) + 1).toString().length === 1
        ? `0${monthDay.indexOf(currentMonth) + 1}`
        : monthDay.indexOf(currentMonth) + 1;
    setDefaultDate(e.target.innerText);
    clevertap.event.push("pickup_time_updated");
  };

  //All function and const that are define Ends here
  // All useEffect written here
  useEffect(() => {
    var arr = [];
    for (var i = 0; i < 5; i++) {
      var date = new Date();
      var newDate = date.setDate(date.getDate() + i);
      let show = new Date(newDate);
      let onlyDate = show.getDate();
      let year = show.getFullYear();
      var month = monthDay[show.getMonth()];
      var week = weekDay[show.getDay()];
      if (onlyDate.toString().length === 2) {
        arr.push({ week, onlyDate, month, year });
      } else {
        onlyDate = "0" + onlyDate;
        arr.push({ week, onlyDate, month, year });
      }
    }
    let string = arr[0].week + " " + arr[0].onlyDate + " " + arr[0].month;
    setDateArr(arr);
    setDefaultDate(string);
    dateTimeValue.CurrentYear = arr[0].year;
    dateTimeValue.CurrentDate =
      arr[0].onlyDate.toString().length === 1
        ? `0${arr[0].onlyDate}`
        : arr[0].onlyDate;
    dateTimeValue.currentMonth =
      (monthDay.indexOf(arr[0].month) + 1).toString().length === 1
        ? `0${monthDay.indexOf(arr[0].month) + 1}`
        : monthDay.indexOf(arr[0].month) + 1;
  }, []);

  useEffect(() => {
    if (val.getMinutes() === 0) {
      setDateTimeString(
        `${dateTimeValue.CurrentDate}/${dateTimeValue.currentMonth}/${
          dateTimeValue.CurrentYear
        } ${val.getHours()}:0${val.getMinutes()}`
      );
    } else if (val.getMinutes() !== 0) {
      setDateTimeString(
        `${dateTimeValue.CurrentDate}/${dateTimeValue.currentMonth}/${
          dateTimeValue.CurrentYear
        } ${val.getHours()}:${val.getMinutes()}`
      );
    }

    // setOneWayETATimeString
    if (showETA && showWhen[0].label.includes("Now")) {
      let dt = new Date();
      dt.setMinutes(dt.getMinutes() + etaOneWayTime);
      // let a = dt.toLocaleTimeString();
      let hr = dt.getHours();
      let min = dt.getMinutes();

      setOneWayETATimeString(
        `${dateTimeValue.CurrentDate}/${dateTimeValue.currentMonth}/${dateTimeValue.CurrentYear} ${hr}:${min}`
      );
    }
  }, [defaultDate, val, etaOneWayTime]);

  const checkingCouponApi = () => {
    if (!coupanText === true) {
      setCoupanSucessMessage("Coupon Code Required");
      setShowCoupanMessage(true);
      setTimeout(() => {
        setCoupanSucessMessage("");
      }, 3000);
      setShowErrorColor(false);
    } else if (coupanText.length > 16) {
      setCoupanSucessMessage("Character limit exceeded");
      setShowCoupanMessage(true);
      setTimeout(() => {
        setCoupanSucessMessage("");
      }, 3000);
      setShowErrorColor(false);
    } else if (!cookiesHireDrivers.userid === false) {
      axios({
        method: "GET",
        baseURL: BASE_URL_HULK,
        url:
          !myGateToken === true
            ? `${BASE_URL_HULK}/discount/validate-promo-code/?city=${firstCityId ||
                couponLatLngObj.city_id}&service_type=classic&booking_type=${
                sendingData.booking_type
              }&format=json&promo_code=${coupanText}&src=website&user_id=${
                cookiesHireDrivers.userid
              }&pickup_datetime=${dateTimeString}`
            : `${BASE_URL_HULK}/discount/validate-promo-code/?city=${firstCityId ||
                couponLatLngObj.city_id}&service_type=classic&booking_type=${
                sendingData.booking_type
              }&format=json&promo_code=${coupanText}&src=my_gate&user_id=${
                cookiesHireDrivers.userid
              }&pickup_datetime=${dateTimeString}`,
        withCredentials: false,
        headers: {
          "Content-Type": `application/json`,
        },
      })
        .then(({ data }) => {
          if (data.status === "success") {
            setCouponCodeToggle(true);
            setShowErrorColor(true);
            setCoupanSucessMessage(data.message);
            setCouponCodeData(data);
            setPromoBottomSheet(false);
            setPromoPopUpSheet(false);
            sendingData.promo_code = coupanText;
            setShowCoupanMessage(true);
            couponVerifyClickedObj.coupon_code = coupanText;
            couponVerifyClickedObj.status = "valid";
            couponVerifyClickedObj.client = myGateToken ? "mygate" : "website";
            couponVerifyClickedObj.error_reason = "N/A";
            clevertap.event.push(
              "coupon_verify_clicked",
              couponVerifyClickedObj
            );
          } else if (data.status === "error") {
            setCouponCodeToggle(false);
            setShowErrorColor(false);
            setCoupanSucessMessage(data.message);
            setTimeout(() => {
              setCoupanSucessMessage("");
            }, 3000);
            setShowCoupanMessage(true);
            couponVerifyClickedObj.coupon_code = coupanText;
            couponVerifyClickedObj.status = "invalid";
            couponVerifyClickedObj.client = myGateToken ? "mygate" : "website";
            couponVerifyClickedObj.error_reason = data.message;
            clevertap.event.push(
              "coupon_verify_clicked",
              couponVerifyClickedObj
            );
          }
        })
        .catch((err) => {
          toast.error(err.message);
          console.log(err.message);
        });
    }
  };
  const handleCreateBooking = () => {
    if (!firstCityId === true || !secondCityId === true) {
      setMisMatchCityId(true);
      setTimeout(() => {
        setMisMatchCityId(false);
      }, 1000);
    } else {
      sendingData.city = firstCityId;
      sendingData.car_type = oneWayCarTypeString;
      sendingData.my_gate_session_id = myGateToken;
      if (oneWayChecked === false) {
        setIsOpenIns(true);
      } else {
        sendingData.is_insured = oneWayChecked;

        if (myGateToken && consentFlag === false) {
          setMyGatePopUp(true);
          setPreviousChoice("MyGateRequestBooking");
        } else {
          if (isAuthenticated === false && !myGateToken === true) {
            setShowPopUp(true);
            setPreviousChoice("RequestBooking");
          } else {
            handleBookDriverBooking();
          }
        }
      }
    }
  };

  const fetchFareOneWayNew = (fareData) => {
    const driveData = fareData.find(
      (service) => service.service_type === "classic"
    );
    setFareExtraInfo(driveData.extra_info);
    setEventFareEstimate(driveData.total_fare);
    setFareEstimate(driveData.total_fare);
    setFareEstimateDetail(driveData.fare_details);
    setCancelFarePopOver(driveData.cancel_info);
  };
  const fetchFairApi = () => {
    fareParams.booking_type = "one_way_trip";
    fareParams.num_hours = 0;
    fareParams.user_id = sendingData.user_id;
    fareParams.car_type = oneWayCarTypeString;
    fareParams.is_insured = +oneWayChecked;
    fareParams.pickup_datetime = dateTimeString;
    fareParams.user_id = cookiesHireDrivers.userid;
    fareParams.city_id = !firstCityId === true ? "" : firstCityId;

    setFareSpinner(true);
    const res = axios({
      method: "GET",
      baseURL: BASE_URL,
      url: `${BASE_URL}/web/fare-estimate-v2/`,
      withCredentials: false,
      params: fareParams,
      headers: {
        "Content-Type": `application/json`,
      },
    });
    res
      .then(({ data }) => {
        if (data.status === "success") {
          fetchFareOneWayNew(data.service_types);
          setShowFareEstimate(true);
          setFareSpinner(false);
          fareParams.distance = data.distance;
          fareParams.duration = data.duration;
          fareParams.pickup_datetime = dateTimeString;

          sendingData.distance = data.distance;
          sendingData.duration = data.duration;
          sendingData.pickup_datetime = dateTimeString;
          sendingData.estimated_trip_minutes = 0;
          sendingData.estimated_trip_hours = 0;
        }
      })
      .catch((err) => {
        console.log(err.message);
        setFareSpinner(false);
        toast.error(err.message);
      });

    // axios({
    //   method: "GET",
    //   baseURL: BASE_URL_HULK,
    //   url: `${BASE_URL_HULK}/pricing/fare-estimate/?city_id=${cityId}&service_type=${sendingData.service_type}&pickup_datetime=${dateTimeString}&user_id=${cookiesHireDrivers.userid}&booking_type=${sendingData.booking_type}&pickup_latitude=${sendingData.pickup_latitude}&pickup_longitude=${sendingData.pickup_longitude}&drop_latitude=${sendingData.drop_latitude}&drop_longitude=${sendingData.drop_longitude}`,
    //   withCredentials: false,
    //   headers: {
    //     "Content-Type": `application/json`,
    //   },
    // })
    //   .then(({ data }) => {
    //     if (data.status === "success") {
    //       setEventFareEstimate(data.fare_range);
    //       setFareEstimate(data.fare_range);
    //       setShowFareEstimate(true);
    //       setFareSpinner(false);
    //       // setFareEstimateMessage(data.sub_text);
    //       setFareEstimateDetail(data.fare_details);
    //       sendingData.estimated_trip_minutes = data.num_minutes;
    //       sendingData.estimated_trip_hours = data.num_hours;
    //       sendingData.pickup_datetime = dateTimeString;
    //     }
    //   })
    //   .catch((err) => {
    //     setFareSpinner(false);
    //     console.log(err.message);
    //   });
  };

  let firstToggle = toggleFareEstimate.oneWayFirstToggle;
  let secondToggle = toggleFareEstimate.oneWaySecondToggle;

  useEffect(() => {
    setDateTimeAllWay(dateTimeString);
    if (firstToggle && secondToggle && firstCityId === secondCityId) {
      fetchFairApi();
    }
  }, [firstToggle, secondToggle, dateTimeString, oneWayChecked]);

  useEffect(() => {
    // when Ref start
    let controlWhen = whenTypeRef.current.children[0].children[0];
    controlWhen.style.borderStyle = "none";
    controlWhen.style.borderBottom = "0.2px solid #888";
    controlWhen.style.backgroundColor = "white";
    controlWhen.style.color = "#666";
    // when ref end
    let control =
      checkRef.current.children[0].children[0].children[0].children[1]
        .children[0];
    control.setAttribute("inputmode", "none");
    // ControlBorder
    let controlBorder = checkRef.current.children[0].children[0];
    controlBorder.style.borderStyle = "none";
    controlBorder.style.borderBottom = "0.2px solid #888";

    const carTypeControl = CarTypeRef.current.children[0].children[0];
    const carTypeControlKeyBoard =
      CarTypeRef.current.children[0].children[0].children[0].children[1]
        .children[0];
    carTypeControlKeyBoard.setAttribute("inputmode", "none");
    carTypeControl.style.borderStyle = "none";
    carTypeControl.style.color = "#666";
    carTypeControl.style.backgroundColor = "white";
    carTypeControl.style.borderBottom = "0.2px solid #888";

    const carModelControl = CarTypeRef.current.children[1].children[0];
    const carModelControlKeyBoard =
      CarTypeRef.current.children[1].children[0].children[0].children[1]
        .children[0];
    carModelControlKeyBoard.setAttribute("inputmode", "none");
    // carModelControl.setAttribute("inputmode", "none");
    carModelControl.style.borderStyle = "none";
    carModelControl.style.color = "#666";
    carModelControl.style.backgroundColor = "white";
    carModelControl.style.borderBottom = "0.2px solid #888";

    const whenTypeControl =
      whenTypeRef.current.children[0].children[0].children[0].children[1]
        .children[0];
    whenTypeControl.setAttribute("inputmode", "none");
  }, []);

  useEffect(() => {
    setFareSpinner(false);
    setShowFareEstimate(false);
  }, [activeKey]);

  useEffect(() => {
    if (val.getDate() === +todayDate) {
      setVal(currentPickUpDate);
    }
  }, [defaultDate]);

  const handleFillPromoCode = (promoCode) => {
    setCoupanText(promoCode);
    setCouponCodeLength(true);
    axios({
      method: "GET",
      baseURL: BASE_URL_HULK,
      url:
        !myGateToken === true
          ? `${BASE_URL_HULK}/discount/validate-promo-code/?city=${firstCityId ||
              couponLatLngObj.city_id}&service_type=classic&booking_type=${
              sendingData.booking_type
            }&format=json&promo_code=${promoCode}&src=website&user_id=${
              cookiesHireDrivers.userid
            }&pickup_datetime=${dateTimeString}`
          : `${BASE_URL_HULK}/discount/validate-promo-code/?city=${firstCityId ||
              couponLatLngObj.city_id}&service_type=classic&booking_type=${
              sendingData.booking_type
            }&format=json&promo_code=${promoCode}&src=my_gate&user_id=${
              cookiesHireDrivers.userid
            }&pickup_datetime=${dateTimeString}`,
      withCredentials: false,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "success") {
          setCouponCodeData(data);
          setShowErrorColor(true);
          setCoupanSucessMessage(data.message);
          sendingData.promo_code = promoCode;
          setCouponCodeToggle(true);
          setShowCoupanMessage(true);
          setPromoBottomSheet(false);
          setPromoPopUpSheet(false);
        } else if (data.status === "error") {
          setCouponCodeToggle(false);
          setShowErrorColor(false);
          setCoupanSucessMessage(data.message);
          setTimeout(() => {
            setCoupanSucessMessage("");
          }, 3000);
          setShowCoupanMessage(true);
        }
      })
      .catch((err) => {
        console.log(err.message);
        toast.error(err.message);
      });
  };
  const handleChangeSetTimeVal = (value) => {
    setVal(value);
    const PickUpTimeUpdated = {
      client: myGateToken ? "mygate" : "website",
    };
    clevertap.event.push("pickup_time_updated", PickUpTimeUpdated);
  };
  const handleOpenSecureToolTip = () => {
    setOpen((s) => !s);
    const duSecureInfoViewed = {
      client: myGateToken ? "mygate" : "website",
    };
    clevertap.event.push("du_secure_info_viewed", duSecureInfoViewed);
  };
  const handleApplyPromocode = () => {
    sendingData.pickup_datetime = dateTimeString;
    if (windowWidth > 768) {
      if (
        !myGateToken === true &&
        (!cookiesHireDrivers.userid === true ||
          (cookiesHireDrivers.userid === "undefined" &&
            typeof cookiesHireDrivers.userid === "string"))
      ) {
        setShowPopUp(true);
        setPreviousChoice("NavbarSignUp");
      } else if (myGateToken && consentFlag === false) {
        setMyGatePopUp(true);
      } else {
        handleGetAllPromoCode();
        setPromoPopUpSheet(true);
      }
    } else {
      if (
        !myGateToken === true &&
        (!cookiesHireDrivers.userid === true ||
          (cookiesHireDrivers.userid === "undefined" &&
            typeof cookiesHireDrivers.userid === "string"))
      ) {
        setShowPopUp(true);
        setPreviousChoice("NavbarSignUp");
      } else if (myGateToken && consentFlag === false) {
        setMyGatePopUp(true);
      } else {
        handleGetAllPromoCode();
        setPromoBottomSheet(true);
      }
    }
  };
  const handleOneWayChecked = (e) => {
    setOneWayChecked(e.target.checked);
    const duSecureAdjustObj = {
      action: "",
      client: myGateToken ? "mygate" : "website",
    };
    if (e.target.checked === true) {
      duSecureAdjustObj.action = "applied";
    } else if (e.target.checked === false) {
      duSecureAdjustObj.action = "removed";
    }
    clevertap.event.push("du_secure_adjusted", duSecureAdjustObj);
  };

  const handleChangeETA = (params) => {
    setShowWhen(params.value);
    const demandTypeObj = {
      demand: "",
      client: myGateToken ? "mygate" : "website",
    };
    if (params.value[0].label.includes("Now")) {
      demandTypeObj.demand = "now";
    } else {
      demandTypeObj.demand = "later";
    }
    clevertap.event.push("demand_type_updated", demandTypeObj);
  };

  const handleRemoveSuccessCoupon = () => {
    setCouponCodeToggle(false);
    setCoupanSucessMessage("");
    setCoupanText("");
    setCouponCodeLength(false);
  };

  const handleGetOtherOffers = () => {
    if (windowWidth > 768) {
      setPromoPopUpSheet(true);
    } else {
      setPromoBottomSheet(true);
    }
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="oneWay">
          <div
            style={{ marginTop: "-1rem" }}
            className="inputBox oneWayInputBox"
          >
            <div style={{ color: "#888" }} className="fontMedium">
              Choose Location
            </div>
            <OneWayInput
              handleRemoveSuccessCoupon={handleRemoveSuccessCoupon}
            />
            <OneWaySecondInput />
          </div>

          <div>
            <div className="oneWayBottomDiv">
              {/* start */}
              <div
                style={{ display: showETA === false ? "none" : "" }}
                className="CarTypeBlock"
              >
                <div className="fontMedium">When is driver needed ? </div>
                <div className="CarType" ref={whenTypeRef}>
                  <Select
                    clearable={false}
                    options={[
                      {
                        label: showETA
                          ? `Now - in ${etaOneWayTime} min`
                          : "Now",
                        id: "#F0F8FF",
                      },
                      { label: "Schedule for later", id: "#FAEBD7" },
                    ]}
                    placeholder="When you need driver"
                    value={showWhen}
                    searchable={false}
                    onChange={handleChangeETA}
                  />
                </div>
              </div>

              {/* end */}

              <div
                style={{
                  display:
                    showETA === false
                      ? ""
                      : showWhen.length !== 0 &&
                        showWhen[0].label.includes("Now")
                      ? "none"
                      : "",
                }}
              >
                <div className="OneWayDateAndTime fontMedium">Date & Time</div>

                <div className="DataAndTimeInput">
                  <div className="Date" onClick={handleDate}>
                    <div style={{ width: "70%" }}>
                      <input
                        placeholder="Select Date"
                        className="dateInput"
                        type="none"
                        value={defaultDate}
                        readOnly
                      />
                    </div>
                    <div style={{display : "flex" , justifyContent: "start" , alignItems : "center"}}>
                      <FaCaretDown size={15} style={{ width: "0.7em"}} />
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      setShowDate(false);
                    }}
                    ref={checkRef}
                    className="Time"
                  >
                    {val.getDate() === +todayDate ? (
                      <TimePicker
                        className="carDetailOption"
                        value={val}
                        onChange={handleChangeSetTimeVal}
                        minTime={new Date(Math.round(date.getTime() / ms) * ms)}
                      />
                    ) : (
                      <TimePicker
                        className="carDetailOption"
                        value={val}
                        onChange={handleChangeSetTimeVal}
                        minTime={
                          showMinTime
                            ? ""
                            : new Date(Math.round(date.getTime() / ms) * ms)
                        }
                      />
                    )}
                  </div>
                </div>

                <div style={{ marginTop: "0.5rem" }} className="dateDiv">
                  {dateArr.map((elem, index) => (
                    <div
                      className="dateOption"
                      onClick={handleDateString(
                        elem.year,
                        elem.month,
                        elem.onlyDate
                      )}
                      style={{
                        display: showDate ? "" : "none",
                      }}
                      key={index}
                    >
                      {elem.week} {elem.onlyDate} {elem.month}
                    </div>
                  ))}
                </div>
              </div>

              {/* car Type */}

              <div
                onClick={() => {
                  setShowDate(false);
                }}
                className="CarTypeBlock"
              >
                <div style={{ color: "#888" }} className="fontMedium">
                  Car Type
                </div>
                <div className="CarType" ref={CarTypeRef}>
                  <Select
                    clearable={false}
                    options={[
                      { label: "Manual", id: "#F0F8FF" },
                      { label: "Automatic", id: "#FAEBD7" },
                    ]}
                    value={showCarType}
                    searchable={false}
                    onChange={(params) => setShowCarType(params.value)}
                  />
                  <Select
                    clearable={false}
                    options={[
                      { label: "Hatchback", id: "#F0F8FF" },
                      { label: "Sedan", id: "#FAEBD7" },
                      { label: "SUV", id: "#00FFFF" },
                      { label: "Luxury", id: "#7FFFD4" },
                    ]}
                    value={showCarModel}
                    searchable={false}
                    onChange={(params) => setShowCarModel(params.value)}
                  />
                </div>
              </div>

              {couponCodeToggle === false ? (
                <div onClick={handleApplyPromocode}>
                  <div className="newPromoContainer">
                    <AiOutlinePercentage className="newPromoDiscountImage" />
                    <div className="fontMedium couponText">
                      <span>Apply Coupon</span>
                      <MdKeyboardArrowRight
                        style={{ color: "#191C19", fontSize: "1rem" }}
                      />
                    </div>
                  </div>

                  {myGateToken ? (
                    <div
                      style={{
                        display:
                          myGateTokenData.length === 0 ? "none" : "block",
                      }}
                      className="actionOffer"
                    >
                      Click above to see offers
                    </div>
                  ) : (
                    <div
                      style={{
                        display:
                          !cookiesHireDrivers.userid === true ||
                          cookiesHireDrivers.userid === "undefined"
                            ? "none"
                            : "block",
                      }}
                      className="actionOffer"
                    >
                      Click above to see offers
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="newPromoSuccessContainer">
                    <div className="successPromoContainer">
                      <div className="successPromoF1">
                        <div className="PromoF1">
                          <div className="PromoF11">
                            <FaCheckCircle />
                          </div>
                          <div className="PromoF12">{coupanText} Applied</div>
                        </div>
                        <div className="PromoF2">
                          {couponCodeData.title === ""
                            ? "Code Applied"
                            : couponCodeData.title}
                        </div>
                      </div>
                      <div
                        className="successPromoF2"
                        onClick={handleRemoveSuccessCoupon}
                      >
                        Remove
                      </div>
                    </div>
                    <hr className="successLineL1" />
                    <div
                      className="otherOffersO1"
                      onClick={handleGetOtherOffers}
                    >
                      Other Offers
                    </div>
                  </div>
                </>
              )}

              <div
                style={{
                  color: "#888",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginBottom: "0.4rem",
                  marginTop: "1rem",
                  fontFamily: "Circular-Std-Book",
                }}
                className="InsuranceBlock"
              >
                SECURE YOUR BOOKING &nbsp;
                <ModalStateContainer>
                  {({ isConfirmationOpen, toggleConfirm }) => (
                    <React.Fragment>
                      <MdInfoOutline
                        style={{
                          fontSize: "14px",
                          // color: myGateToken ? " #f93356" : "#127e40",
                          color :msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40",
                          cursor: "pointer",
                          fontFamily: "Circular-Std-Book",
                        }}
                        onClick={handleOpenSecureToolTip}
                      />

                      <Modal onClose={() => setOpen(false)} isOpen={isOpen}>
                        <FocusOnce>
                          <ModalHeader
                            style={{
                              fontFamily: "Circular-Std-Bold",
                              color: "#09081a",
                              opacity: 0.7,
                              outline: "none",
                            }}
                          >
                            DriveU Secure
                          </ModalHeader>
                        </FocusOnce>
                        <ModalBody>
                          <center
                            style={{
                              fontFamily: "Circular-Std-Book",
                            }}
                          >
                            <h4
                              style={{
                                marginBottom: "0.5rem",
                                color: "#09081a",
                                opacity: 0.7,
                              }}
                            >
                              Vehicle Damage Protection Plan
                            </h4>
                            <table>
                              <tbody className="insTableBody">
                                <tr>
                                  <td>DU Secure Fee&nbsp;</td>
                                  <td>&nbsp;₹{`${premium}`}/-</td>
                                </tr>
                                <tr>
                                  <td>GST&nbsp;</td>
                                  <td>&nbsp;₹{`${gst}`}/-</td>
                                </tr>
                                <tr>
                                  <td>Total&nbsp;</td>
                                  <td>&nbsp;₹{`${+premium + +gst}`}/-</td>
                                </tr>
                              </tbody>
                            </table>
                          </center>
                          <br />
                          <ol
                            style={{
                              fontFamily: "Circular-Std-Book",
                              marginLeft: "24px",
                              color: "#09081a",
                              opacity: 0.7,
                            }}
                          >
                            <li>Vehicle damages up to ₹15,000*</li>
                            <li>
                              Claim to be raised within 3 days of trip end
                            </li>
                          </ol>
                        </ModalBody>
                        <ModalFooter>
                          <center
                            style={{
                              fontFamily: "Circular-Std-Book",
                              color: "#666",
                              marginBottom: "16px",
                            }}
                          >
                            {myGateToken ? (
                              <div
                                style={{
                                  opacity: "0.7",
                                  // color: myGateToken ? " #f93356" : "#127e40",
                                  color : msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40",
                                }}
                                onClick={() => toggleConfirm(true)}
                              >
                                View Terms and Conditions
                              </div>
                            ) : (
                              <Link
                                to="/tnc"
                                style={{
                                  opacity: "0.7",
                                  color: myGateToken ? " #f93356" : "#127e40",
                                }}
                              >
                                View Terms and Conditions
                              </Link>
                            )}
                          </center>
                        </ModalFooter>
                      </Modal>

                      <Modal
                        onClose={() => toggleConfirm(false)}
                        isOpen={isConfirmationOpen}
                      >
                        <ModalHeader>DRIVEU TERMS AND CONDITIONS</ModalHeader>
                        <ModalBody>
                          <div
                            style={{
                              width: "100%",
                              height: "500px",
                              overflow: "scroll",
                            }}
                          >
                            <TermConditionNew />
                          </div>
                        </ModalBody>
                      </Modal>
                    </React.Fragment>
                  )}
                </ModalStateContainer>
              </div>
              <Checkbox
                checked={oneWayChecked}
                onChange={handleOneWayChecked}
                labelPlacement={LABEL_PLACEMENT.right}
                overrides={{
                  Label: {
                    style: ({ $theme }) => ({
                      color: "#888",
                      fontSize: "12px",
                      fontWeight: "400px",
                      marginBottom: "0.5rem",
                      fontFamily: "Circular-Std-Book",
                      paddingRight: "1rem",
                      lineHeight: "150%",
                    }),
                    Checkmark: {
                      style: ({ $theme }) => ({
                        outline: `${$theme.colors.warning200} solid`,
                        backgroundColor: "black !important",
                      }),
                    },
                  },
                }}
              >
                {/* Get comprehensive coverage for you and your car powered by Mars
                Insurance for ₹{`${+premium + +gst}`}/- T&C apply* */}
                Get vehicle damage protection plan for your car for ₹
                {`${+premium}`} +GST. T&C apply*
              </Checkbox>
              <div
                style={{
                  display: showFareEstimate ? "" : "none",
                }}
              >
                <div
                  style={{
                    color: "#888",
                    fontSize: "12px",
                    fontWeight: "400",
                    textAlign: "center",
                    marginBottom: "0.5rem",
                    fontFamily: "Circular-Std-Book",
                  }}
                >
                  -----------
                  <span style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                    FARE ESTIMATE
                  </span>
                  -------------
                </div>
                {fareSpinner ? (
                  <center>
                    <LoadingSpinner />
                  </center>
                ) : (
                  <div
                    style={{
                      color: "#0085b2",
                      fontSize: "30px",
                      fontWeight: "400",
                      marginBottom: "0.5rem",
                      textAlign: "center",
                      fontFamily: "Circular-Std-Book",
                    }}
                  >
                    ₹{fareEstimate}
                    <MdInfoOutline
                      style={{
                        fontSize: "30px",
                        cursor: "pointer",
                        color: "gray",
                        paddingTop: "0.4rem",
                      }}
                      onClick={() => setIsFairEstimate(true)}
                    />
                    {isFairEstimate ? (
                      <FairEstimate
                        setIsFairEstimate={setIsFairEstimate}
                        isFairEstimate={isFairEstimate}
                      />
                    ) : null}
                  </div>
                )}
                <div
                  style={{
                    color: "#888",
                    fontSize: "12px",
                    fontWeight: "400",
                    textAlign: "center",
                    marginBottom: "0.5rem",
                    marginTop: "0.5rem",
                    fontFamily: "Circular-Std-Book",
                    paddingRight: "1rem",
                  }}
                >
                  {/* This is just an estimate */}
                  This is just an estimate, actual fare will be calculated after
                  the drive.
                  {/* {fareEstimateMessage} */}
                </div>
              </div>
            </div>
            <div
              style={{
                fontFamily: "Circular-Std-Book",
                marginBottom: "0.5rem",
                marginTop: "0.5rem",
              }}
              className="err-msg-text"
            >
              {isErrMsgBooking}
            </div>
            &nbsp;
            {showBookLoader ? (
              <Button
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  backgroundColor: myGateToken ? " #f93356" :"#127e40",
                  color: "#ffffff",
                  paddingLeft: "0rem !important",
                  marginTop: "-1rem",
                  fontfamily: "Circular-Std-Medium",
                }}
                isLoading
              >
                <span className="RequestDriver">Request Driver</span>
              </Button>
            ) : (
              <Button
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  backgroundColor: msilTokenData.token && msilTokenData.utm_source == "msil" ? "#2C3394" : myGateToken ? " #f93356" : "#127e40",
                  color: "#ffffff",
                  paddingLeft: "0rem !important",
                  marginTop: "-1rem",
                  fontfamily: "Circular-Std-Medium",
                }}
                id="OneWay-Booking"
                onClick={handleCreateBooking}
              >
                <span className="RequestDriver">Request Driver</span>
              </Button>
            )}
          </div>
        </div>
      </form>

      <PromoBottomSheet
        promoBottomSheet={promoBottomSheet}
        setPromoBottomSheet={setPromoBottomSheet}
        setCoupanSucessMessage={setCoupanSucessMessage}
        coupanSucessMessage={coupanSucessMessage}
        couponCodeLength={couponCodeLength}
        setCouponCodeLength={setCouponCodeLength}
        checkingCouponApi={checkingCouponApi}
        coupanText={coupanText}
        setCoupanText={setCoupanText}
        showCoupanMessage={showCoupanMessage}
        showErrorColor={showErrorColor}
        handleFillPromoCode={handleFillPromoCode}
        couponData={couponData}
      />
      <PromoPopUpSheet
        promoPopUpSheet={promoPopUpSheet}
        setPromoPopUpSheet={setPromoPopUpSheet}
        setCoupanSucessMessage={setCoupanSucessMessage}
        coupanSucessMessage={coupanSucessMessage}
        couponCodeLength={couponCodeLength}
        setCouponCodeLength={setCouponCodeLength}
        coupanText={coupanText}
        setCoupanText={setCoupanText}
        checkingCouponApi={checkingCouponApi}
        showCoupanMessage={showCoupanMessage}
        showErrorColor={showErrorColor}
        handleFillPromoCode={handleFillPromoCode}
        couponData={couponData}
      />

      <ConsentPopUp couponCodeToggle={couponCodeToggle} />
      {showPopUp ? <div className="overlay" /> : null}
      {showPopUp ? <RegistrationForm className="form_number" /> : null}
      {isOpenIns ? <InsuranceConfirm /> : null}
    </>
  );
}

export default OneWayBooking;
