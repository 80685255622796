import React, { useEffect, useState, useContext } from "react";
import "./PopUpOtp.css";
import axios from "axios";
import { CloseBtn } from "../../../AllImportFiles/ImportSvg";
import Spinner from "../../../../Uis/Spinner/Spinner";
// appsflyer start here
import CleverTap from "clevertap-web-sdk";
import { getClientId } from "../../../../utils/getClientId";
// appsflyer end here
//COOKIES AND REACT-ROUTER-DOM
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
// REDUX
import { useDispatch } from "react-redux";
import { getOtp } from "../../../../Redux/APICALL/action";
import { changeViewModal } from "../../../../Redux/APICALL/action";
//CONTEXT API
import { BookingFlowDataContext } from "../../../../context/BookingFlowDataContextProvider";
import { BookingFlowParaContext } from "../../../../context/BookingFlowParaProvider";
import { DownloadContext } from "../../../../context/DownloadContextProvider";
import { FastagContext } from "../../../../context/FastagContextProvider";
import { RSAContext } from "../../../../context/RSAContextProvider";
import { WashSubscriptionContext } from "../../../../context/WashSubscriptionContextProvider";

function PopUpOtp({ userNumber, changeFunction }) {
  // ENV VARIABLES
  const BASE_URL_HULK = process.env.REACT_APP_HULK_URL;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const PROFILE_API_SRC = process.env.REACT_APP_PROFILE_API_SRC;
  // STATE
  const [timer, setTimer] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isVerified, setIsVerified] = useState({
    otp_code: "",
    mobile: userNumber.mobile,
    clevertap_id : CleverTap.getCleverTapID(),
    utm_source: localStorage.getItem('utm_source') || "",
    utm_medium: localStorage.getItem('utm_medium') || "",
    utm_campaign: localStorage.getItem('utm_campaign') || "",
    pseudo_user_id: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  // CONTEXT
  const {
    previousChoice,
    setIsFastTagLoading,
    setShowPopUp,
    handleClosePopUpOTP,
  } = useContext(DownloadContext);
  const { setShowRSAPopUp } = useContext(RSAContext);
  const {
    sendingData,
    setUserInfo,
    sendingRoundWayData,
    setShowBookLoaderRound,
    sendingOutstationData,
  } = useContext(BookingFlowDataContext);
  const { setIsAuthenticated, setUserName, setShowBookLoader } = useContext(
    BookingFlowParaContext
  );
  const { setActiveArray, setSubScriptionPlan, setLottieLoader } = useContext(
    WashSubscriptionContext
  );
  const {
    setSendLink,
    setShowRecharge,
    setCloudUserId,
    compareWidth,
  } = useContext(FastagContext);


  // implement appsflyer start here
  const [utmParams, setUtmParams] = useState([]);

  const [cleverTapId, setCleverTapId] = useState(null);
  const [clientId, setClientId] = useState(null);
  useEffect(() => {
    // Function to get CleverTap ID
    const getCleverTapId = () => {
      const identity = CleverTap.getCleverTapID();
      setCleverTapId(identity);
    };
    // Call the function to get CleverTap ID
    getCleverTapId();
    getClientId().then((clientId) => {
      setClientId(clientId);
  });
  }, []);

  // implement appsflyer end here

  // COOKIES AND REACT-ROUTER-DOM
  const [, setCookie] = useCookies(["userid"]);
  const navigate = useNavigate();
  // DISPATCH
  const dispatch = useDispatch();

  // OTP SEND AND OTP INPUT FUNCTION
  const handleOtpInp = (event) => {
    const { name, value } = event.target;
    setIsVerified({ ...isVerified, [name]: value });
  };
  const handleResendOtp = () => {
    if (timer === true) {
      dispatch(getOtp(userNumber));
      setTimer(false);
      setCounter(60);
    } else {
      console.log(null);
    }
  };

  // VALIDATE FOR ONLY NUMBER INPUT
  function validate(e) {
    let key;
    var theEvent = e || window.event;
    if (theEvent.type === "paste") {
      key = e.clipboardData.getData("text/plain");
    } else {
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  // VERIFY OTP FUNCTION AND REDIRECT ACCORDING TO PREVIOUS CHOICE FROM WHERE POPUP INITIATED
  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (e.target.otp_code.value === "") {
      setErrMsg("Please write valid OTP");
      setTimeout(() => {
        setErrMsg("");
      }, 10000);
    } else if (e.target.otp_code.value.length !== 4) {
      setErrMsg("Please write valid OTP");
      setTimeout(() => {
        setErrMsg("");
      }, 10000);
    } else {
      setLoading(true); 
      axios.defaults.withCredentials = true;
      isVerified.pseudo_user_id = clientId;
      axios({
        method: "POST",
        baseURL: BASE_URL,
        url: "/web/verify-number/?src=website",
        data: isVerified,
        withCredentials: true,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then(({ data }) => {
          let user_id = data.uuid;
          setCloudUserId(user_id);
          // document.cookie = `userid=${user_id}`;
          setCookie("userid", data.uuid, { path: "/" });
          setCookie("sdk_initialize", false, { path: "/" });
          localStorage.clear();
          if (
            data.is_existing_user &&
            data.status === "success" &&
            previousChoice === "fasttag"
          ) {
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
                setCookie("userid", data.user_id, { path: "/" });
              })
              .catch((err) => {
                console.log(err.message);
              });
            setIsFastTagLoading(true);
            axios({
              method: "GET",
              baseURL: BASE_URL_HULK,
              url: `${BASE_URL_HULK}/care/setu/links/?category=Fastag&user_id=${user_id}`,
              withCredentials: false,
              headers: {
                "Content-Type": `multipart/form-data`,
              },
            })
              .then(({ data }) => {
                setSendLink(data.link);
                if (compareWidth > 1279) {
                  setShowRecharge(true);
                } else {
                  window.open(data.link, "_blank");
                }
                setIsFastTagLoading(false);
                setShowPopUp(false);
              })
              .catch((error) => {
                alert(error.message);
              });
          } else if (
            data.is_existing_user &&
            data.status === "success" &&
            previousChoice === "hiredrivers"
          ) {
            window.location.href = "/book-driver/";
          } else if (
            data.is_existing_user &&
            data.status === "success" &&
            previousChoice === "road_side_assistance"
          ) {
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
                setCookie("userid", data.user_id, { path: "/" });
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowRSAPopUp(true);
            setShowPopUp(false);
          } else if (
            data.is_existing_user &&
            data.status === "success" &&
            previousChoice === "RequestBooking"
          ) {
            sendingData.user_id = user_id;
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowPopUp(false);

            setShowBookLoader(true);
            axios({
              method: "POST",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              data: sendingData,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setShowBookLoader(false);
                navigate("/my-drives");
              })
              .catch((err) => {
                console.log(err.message);
                setShowBookLoader(false);
              });
          } else if (
            data.is_existing_user &&
            data.status === "success" &&
            previousChoice === "RequestBookingRoundWay"
          ) {
            sendingRoundWayData.user_id = user_id;
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowPopUp(false);
            setShowBookLoaderRound(true);
            axios({
              method: "POST",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              data: sendingRoundWayData,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setShowBookLoaderRound(false);
                navigate("/my-drives");
                // window.location.href = "/my-drives";
              })
              .catch((err) => {
                console.log(err.message);
                setShowBookLoaderRound(false);
              });
          } else if (
            data.is_existing_user &&
            data.status === "success" &&
            previousChoice === "RequestBookingOutstation"
          ) {
            sendingOutstationData.user_id = user_id;
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowPopUp(false);
            setShowBookLoaderRound(true);
            axios({
              method: "POST",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              data: sendingOutstationData,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setShowBookLoaderRound(false);
                navigate("/my-drives");
                // window.location.href = "/my-drives";
              })
              .catch((err) => {
                console.log(err.message);
                setShowBookLoaderRound(false);
              });
          } else if (
            previousChoice === "NavbarSignUp" &&
            data.is_existing_user &&
            data.status === "success"
          ) {
            sendingData.user_id = user_id;
            sendingRoundWayData.user_id = user_id;
            sendingOutstationData.user_id = user_id;
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
                setShowPopUp(false);
              })
              .catch((err) => {
                console.log(err.message);
                setShowPopUp(false);
              });
          } else if (
            previousChoice === "NavbarSignUpWash" &&
            data.is_existing_user &&
            data.status === "success"
          ) {
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
                setShowPopUp(false);
              })
              .catch((err) => {
                console.log(err.message);
                setShowPopUp(false);
              });
          } else if (
            previousChoice === "WashActive" &&
            data.is_existing_user &&
            data.status === "success"
          ) {
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
                setShowPopUp(false);
              })
              .catch((err) => {
                console.log(err.message);
                setShowPopUp(false);
              });

            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/apartment-bookings/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              headers: {
                "Content-Type": `multipart/form-data`,
              },
              withCredentials: false,
            })
              .then(({ data }) => {
                if (data.status === "success") {
                  if (data.bookings.length !== 0) {
                    if (user_id === "" || user_id === undefined || !user_id) {
                      setShowPopUp(true);
                    } else {
                      setActiveArray(data.bookings);
                      navigate("/active-packages/");
                    }
                    // setShowPopUp(false);
                  } else {
                    if (user_id === "" || user_id === undefined || !user_id) {
                      setShowPopUp(true);
                    } else {
                      setLottieLoader(true);
                      axios({
                        method: "GET",
                        baseURL: BASE_URL_HULK,
                        url: `${BASE_URL_HULK}/wash/apartment-bookings/plans/`,
                        withCredentials: false,
                        headers: {
                          "Content-Type": `application/json`,
                        },
                      })
                        .then(({ data }) => {
                          if (data.status === "success") {
                            setSubScriptionPlan(data.plans);
                            navigate("/buy-wash-packages/");
                            setLottieLoader(false);
                          }
                        })
                        .catch((err) => {
                          console.log(err.message);
                        });
                    }
                  }
                }
              })
              .catch((err) => {
                console.log(err.message);
                setShowPopUp(false);
              });
          } else if (
            data.is_existing_user &&
            data.status === "success" &&
            previousChoice === "InsuranceOneWay"
          ) {
            sendingData.user_id = user_id;
            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowPopUp(false);
            setShowBookLoader(true);
            axios({
              method: "POST",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              data: sendingData,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setShowBookLoader(false);
                navigate("/my-drives");
              })
              .catch((err) => {
                console.log(err.message);
                setShowBookLoader(false);
              });
          } else if (
            data.is_existing_user &&
            data.status === "success" &&
            previousChoice === "InsuranceRoundTrip"
          ) {
            sendingRoundWayData.user_id = user_id;

            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowPopUp(false);
            setShowBookLoaderRound(true);
            axios({
              method: "POST",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              data: sendingRoundWayData,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setShowBookLoaderRound(false);
                navigate("/my-drives");
                // window.location.href = "/my-drives";
              })
              .catch((err) => {
                console.log(err.message);
                setShowBookLoaderRound(false);
              });
          } else if (
            data.is_existing_user &&
            data.status === "success" &&
            previousChoice === "InsuranceRoundTrip"
          ) {
            sendingOutstationData.user_id = user_id;

            axios({
              method: "GET",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/profile/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setIsAuthenticated(data.is_authenticated);
                setUserName(data.full_name);
                setUserInfo(data);
              })
              .catch((err) => {
                console.log(err.message);
              });
            setShowPopUp(false);
            setShowBookLoaderRound(true);
            axios({
              method: "POST",
              baseURL: BASE_URL,
              url: `${BASE_URL}/web/book-driver/?src=${PROFILE_API_SRC}&user_id=${user_id}`,
              data: sendingOutstationData,
              withCredentials: false,
              headers: {
                "Content-Type": `application/json`,
              },
            })
              .then(({ data }) => {
                setShowBookLoaderRound(false);
                navigate("/my-drives");
                // window.location.href = "/my-drives";
              })
              .catch((err) => {
                console.log(err.message);
                setShowBookLoaderRound(false);
              });
          } else if (data.is_existing_user && data.status === "success") {
            window.location.href = "/book-driver/";
          } else if (data.is_existing_user === false) {
            dispatch(changeViewModal("email"));
          }
          setTimeout(() => {
            setLoading(false);
          }, 2000);

          let status = data.status;
          if (status === "error") {
            setTimeout(() => {
              setErrMsg("Otp Verification failed");
            }, 2000);
            setTimeout(() => {
              setErrMsg("");
            }, 4000);
          }
        })
        .catch((err) => {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
          setTimeout(() => {
            alert(err.message);
          }, 4000);
        });
    }
  };
  // USE-EFFECT  FOR RESEND OTP
  useEffect(() => {
    counter > 0 &&
      setTimeout(() => {
        setCounter((prev) => prev - 1);
      }, 1000);
    if (counter === 0) {
      setTimer(true);
    }
  }, [counter]);

  return (
    <div className="otp_popup">
      {/* Close button for the OTP verification popup */}
      <div
        style={{ display: previousChoice === "WashActive" ? "none" : "" }}
        id="closeOtp"
      >
        <CloseBtn className="PopUpClose" onClick={handleClosePopUpOTP} />
      </div>
      <div className="content-phone-verify">
        {/* Top content of the OTP verification popup */}
        <div className="top-content-verify">
          <center className="center-img">
            {/* Image displayed at the top of the OTP verification popup */}
            <img
              src="https://ik.imagekit.io/driveu/Website_New/OtpCode_LHV2lKSpt.png"
              alt="OtpImage"
              className="otpimg1"
            />
          </center>
          {/* Heading text for phone number verification */}
          <h2>Please verify your phone number</h2>
          <br />
        </div>
        {/* OTP verification form */}
        <div className="otp-verification">
          <form className="otp_block" onSubmit={handleVerifyOtp}>
            {/* Input field for entering the OTP */}
            <input
              type="text"
              pattern="[0-9]+$"
              maxLength={4}
              placeholder="Enter your OTP number"
              name="otp_code"
              value={isVerified.otp_code}
              onChange={handleOtpInp}
              onKeyPress={validate}
              inputMode="numeric"
              title="write only number"
              autoComplete="off"
              autoFocus
            />
            {/* Button to verify the entered OTP */}
            {loading ? (
              <button className="verify-otp">
                {/* Spinner component displayed when verifying the OTP */}
                <Spinner />
              </button>
            ) : (
              <button className="verify-otp" disabled={loading}>
                Verify OTP
              </button>
            )}
          </form>

          {/* Options for trying OTP verification again */}
          <div className="try_again_options">
            <h3>or</h3>
            {/* Button to resend OTP */}
            <button
              className={timer ? "resend_otp" : "resend_otp_gray"}
              onClick={handleResendOtp}
            >
              {timer ? <>Resend OTP</> : <>Resend OTP in {counter} sec</>}
            </button>
            {/* Text to change the mobile number for OTP verification */}
            <div onClick={changeFunction} className="changeNum">
              Change your mobile number
            </div>
            {/* Error message displayed if there's an issue with OTP verification */}
            <div className="err_msg_otp">{errMsg}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopUpOtp;
